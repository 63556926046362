import { Typography } from "@mui/material";
import React from "react";
import SmartTooltip from "./others/SmartTooltip";

interface ITypographyShortenedProps {
  text: string;
  cutLength?: number;
  modifyTooltip: (arg: string) => any;
}

export function TypographyShortened(props: ITypographyShortenedProps) {
  let tooltip = props.text;
  if (props.modifyTooltip) {
    tooltip = props.modifyTooltip(tooltip);
  }
  let text = props.text;
  if (text!=null && props.cutLength && props.cutLength > 0 && text.length > props.cutLength) {
    text = text.substring(0, props.cutLength - 1) + "…";
  }
  const ret = (
    <SmartTooltip key={tooltip} title={tooltip} >
      <Typography key={text} align="center">{text}</Typography>
    </SmartTooltip>
  );
  return ret;
}
