import { IScrapeLink } from "../../AppModelTypes";
import { useGetScrapeLinksQuery } from "../../api/PlacesApiSlice";
import { usePlaceAttachmentsService } from "../../components/attachments/PlaceAttachmentsService";
import usePlaceService from "../../services/PlaceService";
import createNavigateToProviderAction from "../scrapeLink/NavigateToProviderAction";
import ISmartAction from "./../../components/buttons/ISmartAction";
import useAppService from "./../../services/AppService";
import { useUserService } from "./../../services/UserService";
import createAddAttachmentAction from "./AddAttachmentAction";
import createFavoritePlaceAction from "./FavoritePlaceAction";
import createFlyToPlaceInMapAction from "./FlyToPlaceInMapAction";
import { createNavigateToGoogleMapsAction } from "./NavigateToGoogleMapsAction";
import createNavigateToPlaceDetailsAction from "./NavigateToPlaceDetailsAction";
import createSharePlaceAction from "./SharePlaceAction";
import { concatAllArraysWithoutDuplicates } from "../../ArrayUtils";
import createAddToPlacelistAction from "./AddToPlacelistAction";
import { usePlacelistsService } from "../../services/PlacelistsService";

function usePlaceActions(placeId: string, hideFlyTo?: boolean) {
  if (placeId == null) {
    throw new Error("no place id given");
  }
  const { placeObject } = usePlaceService(placeId);
  const { createPlaceAttachment } = usePlaceAttachmentsService(placeId);
  let scrapeLinks: IScrapeLink[] = [];
  const { data: scrapeLinksQueryResult } = useGetScrapeLinksQuery(placeId);
  if (scrapeLinksQueryResult) {
    scrapeLinks = scrapeLinksQueryResult.records;
  }

  const { navigate, pathname } = useAppService();
  const userService = useUserService();
  const placelistsService = usePlacelistsService();

  const onePlaceActions: ISmartAction[] = [];
  if (placeObject != null) {
    onePlaceActions.push(
      createNavigateToPlaceDetailsAction(placeObject, navigate, pathname)
    );
    if (true !== hideFlyTo) {
      onePlaceActions.push(createFlyToPlaceInMapAction(placeObject, navigate));
    }
    onePlaceActions.push(createNavigateToGoogleMapsAction(placeObject));
    for (let index = 0; index < scrapeLinks.length; index++) {
      const sl = scrapeLinks[index];
      onePlaceActions.push(createNavigateToProviderAction(sl));
    }
    onePlaceActions.push(createFavoritePlaceAction(placeObject, userService));
    onePlaceActions.push(createSharePlaceAction(placeObject));
    if (userService.isSuperuser) {
      onePlaceActions.push(
        createAddAttachmentAction(placeObject, createPlaceAttachment)
      );
    }
    onePlaceActions.push(
      createAddToPlacelistAction(
        placeObject,
        placelistsService.getEditorPlacelists,
        placelistsService.getPlacelist,
        placelistsService.updatePlacelistPlaces
      )
    );
  }
  const allPlaceActions = concatAllArraysWithoutDuplicates([onePlaceActions]);
  const ret = { placeObject, onePlaceActions, allPlaceActions };
  return ret;
}

export default usePlaceActions;
