import { Typography } from "@mui/material";
import React from "react";
import { IPlace, IPlacelist } from "../../AppModelTypes";
import { usePlacelistsService } from "../../services/PlacelistsService";
import SmartAccordionLazy from "../accordion/SmartAccordionLazy";
import PlacelistComponentSmall from "./PlacelistComponentSmall";

interface IProps {
  place: IPlace;
  defaultExpanded?: boolean;
}

function PlacelistsWithPlace({
  place,
  defaultExpanded = false,
}: IProps): JSX.Element | null {
  const { placelists: allPlacelists } = usePlacelistsService();
  function createContent(): JSX.Element | JSX.Element[] {
    let ret: JSX.Element | JSX.Element[] = <></>;
    if (allPlacelists != null) {
      if (allPlacelists.length < 1) {
        ret = (
          <Typography>
            Dieser Platz ist in keiner Placelist enthalten.
          </Typography>
        );
      } else {
        const placelists = _filterPlacelists(allPlacelists);
        ret = (
          <>
            {placelists.map((pl) => {
              // return <GenericInfoComponent dataObject={pl} />;
              return (
                <PlacelistComponentSmall placelist={pl} showPlaces={false} />
              );
            })}
          </>
        );
      }
    }
    return ret;
  }
  function _filterPlacelists(allPlacelists: IPlacelist[]): IPlacelist[] {
    const ret: IPlacelist[] = [];
    for (let i = 0; i < allPlacelists.length; i++) {
      const pl = allPlacelists[i];
      const places = pl.Places;
      if (places) {
        for (let j = 0; j < places.length; j++) {
          const p = places[j];
          const id = p.Id;
          if (id + "" === place.Id + "") {
            ret.push(pl);
          }
        }
      }
    }
    return ret;
  }

  return (
    <SmartAccordionLazy
      key={"SmartAccordionLazy_" + place.Id}
      header="Placelists mit diesem Platz"
      defaultExpanded={defaultExpanded}
      childrenCallback={createContent}
      // disabled={allPlacelists.length < 1}
    />
  );
}

export default PlacelistsWithPlace;

// -----------------------------------
//    Helpers
