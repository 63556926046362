import React from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import { MENU_STRUCTURE } from "./AppConstants";
import Kachelmenu from "./components/Kachelmenu";
import AuthenticationGuard from "./components/auth/AuthenticationGuard";
import AccountInfoPage from "./pages/AccountInfoPage";
import { ErrorPage } from "./pages/ErrorPage";
import MyPlacesPage from "./pages/MyPlacesPage";
import PageWrapper from "./pages/PageWrapper";
import { PlacePage } from "./pages/PlacePage";
import { PlacesListPage } from "./pages/PlacesListPage";
import { SettingsPage } from "./pages/SettingsPage";
import { StatisticsPage } from "./pages/StatisticsPage";
import SearchLocationPage from "./pages/SearchLocationPage";
import { MyPlacelistPage } from "./pages/MyPlacelistPage";
import MyPlacelistsPage from "./pages/MyPlacelistsPage";
import { MapPage } from "./parts/content/MapPage";
import { TestPage } from "./parts/TestPage";
import { LostPlacesPage } from "./parts/LostPlacesPage";

const myPlacesWrapper = () => {
  return <PageWrapper pageTitle="Meine Plätze" page={<MyPlacesPage />} />;
};

const routerChildren = [
  {
    path: "",
    element: (
      <PageWrapper
        pageTitle="Hauptmenü"
        page={<Kachelmenu menuStructure={MENU_STRUCTURE} />}
      />
    ),
    // element: <PageWrapper pageTitle='Home' page={< HomePage />} />,
  },
  {
    path: "menu",
    element: (
      <PageWrapper
        pageTitle="Hauptmenü"
        page={<Kachelmenu menuStructure={MENU_STRUCTURE} />}
      />
    ),
  },
  {
    path: "myaccount",
    element: (
      <PageWrapper pageTitle="Mein Account" page={<AccountInfoPage />} />
    ),
  },
  {
    path: "favorites",
    // element: <AuthenticationGuard component={
    //   <PageWrapper pageTitle='Meine Plätze' page={< MyPlacesPage />} />
    // } />,
    element: <AuthenticationGuard component={myPlacesWrapper} />,
  },
  {
    path: "list",
    element: <PageWrapper pageTitle="Liste" page={<PlacesListPage />} />,
  },
  {
    path: "searchlocation",
    element: (
      <PageWrapper pageTitle="Ort suchen" page={<SearchLocationPage />} />
    ),
  },
  {
    path: "map/:id",
    element: <PageWrapper pageTitle="Karte" page={<MapPage />} />,
  },
  {
    path: "map",
    element: <PageWrapper pageTitle="Karte" page={<MapPage />} />,
  },
  {
    path: "settings",
    element: <PageWrapper pageTitle="Einstellungen" page={<SettingsPage />} />,
  },
  {
    path: "place/:id",
    element: <PageWrapper page={<PlacePage />} />,
  },
  {
    path: "placelists",
    element: <PageWrapper pageTitle="Placelists" page={<MyPlacelistsPage />} />,
  },
  {
    path: "placelist/:id",
    element: <PageWrapper page={<MyPlacelistPage />} />,
  },
];

routerChildren.push({
  path: "lostplaces",
  element: <PageWrapper pageTitle="Lost Places" page={<LostPlacesPage />} />,
});

routerChildren.push({
  path: "statistics",
  element: <StatisticsPage />,
});
// routerChildren.push({
//   path: "tests",
//   element: <Tests />,
// });
routerChildren.push({
  path: "test",
  element: <PageWrapper pageTitle="Test" page={<TestPage />} />,
});

export const AppBrowserRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: routerChildren,
  },
]);
