export function toLatLng(location: any): { lng: number; lat: number } | null {
  if (location != null) {
    const longitude = location.longitude || location.Longitude || location.lng;
    const latitude = location.latitude || location.Latitude || location.lat;

    if (longitude != null && latitude != null) {
      return { lng: longitude, lat: latitude };
    }
  }

  return null;
}
