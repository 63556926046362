import { Grid } from "@mui/material";
import React from "react";
import { ContainerProps } from "./ContainerProps";

const GridContainer = <T extends {}>({
  items,
  renderItem,
}: ContainerProps<T>) => {
  const itemUIs = items.map((item, i) => (
    <Grid item key={i}>
      {renderItem(item)}
    </Grid>
  ));

  return (
    <Grid
      container
      // columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
      spacing={{ xs: 1, xl: 2 }}
    >
      {itemUIs}
    </Grid>
  );
};

export default GridContainer;
