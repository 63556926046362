import React from "react";
import { useParams } from "react-router-dom";
import { IPlacelist } from "../AppModelTypes";
import { usePlacelistsService } from '../services/PlacelistsService';
import { StyledContent } from './../components/styles/styled.Content';
import PlacelistComponentLarge from './../components/placelists/PlacelistComponentLarge';

export function MyPlacelistPage() {
  const { placelists } = usePlacelistsService();
  const routerParams = useParams();

  let p = null;
  if (routerParams && routerParams.id && placelists) {
    const placelistIdString: string = routerParams.id;
    for (let i = 0; i < placelists.length; i++) {
      const oneList: IPlacelist = placelists[i];
      if (oneList.Id === placelistIdString) {
        p = <PlacelistComponentLarge placelist={oneList}/>
        break;
      }
    }
  }

  return (
    <StyledContent>
      {p}
    </StyledContent>
  );
}
