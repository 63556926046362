import { zodResolver } from "@hookform/resolvers/zod";
import { PersonAddTwoTone } from "@mui/icons-material";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { IPlacelist } from "../../AppModelTypes";
import ISmartAction from "../../components/buttons/ISmartAction";
import AddUserForm from "../../components/forms/AddUserForm";
import { IForm } from "../../components/forms/IForm";
import { arrayToText } from "./../../ArrayUtils";

function AddUserToPlacelistAction(placelist: IPlacelist): ISmartAction {
  function handleDialogOk(data: any) {
    //todo: do it!!
  }

  function _contentCreator(props: IForm) {
    const content = (
      <AddUserForm
        data={{}}
        onDataChange={props.onDataChange}
        onError={props.onError}
      />
    );
    return { content };
  }

  const ROLES = ["viewer", "editor"] as const;
  const MIN_CHARACTERS = 6;
  const FormModelSchema = z.object({
    username: z.string().min(MIN_CHARACTERS, {
      message: "Muss mindestens " + MIN_CHARACTERS + " Zeichen lang sein",
    }),
    role: z.enum(ROLES, {
      errorMap: (issue, ctx) => ({
        message:
          "Muss in den Optionen " + arrayToText(ROLES) + " enthalten sein",
      }),
    }),
  });

  type IFormModel = z.infer<typeof FormModelSchema>;

  const defaultValues: IFormModel = {
    username: "Peter",
    role: "viewer",
  };

  const formReturn = useForm<IFormModel>({
    defaultValues: defaultValues,
    resolver: zodResolver(FormModelSchema),
    mode: "all",
  });

  const ret: ISmartAction = {
    text: "Benutzer hinzufügen",
    shortText: "Benutzer...",
    children: <PersonAddTwoTone />,
    dialogProps: {
      title: "Benutzer hinzufügen",
      onCreateContent: _contentCreator,
      onOk: handleDialogOk,
      form: formReturn,
    },
  };
  return ret;
}

export default AddUserToPlacelistAction;
