import { Stack, Toolbar } from "@mui/material";
import React from "react";
import SmartAccordion from "../accordion/SmartAccordion";
import RenameComponent, {
  IRenameComponentProps,
} from "../rename/RenameComponent";
import ISmartAction from "./ISmartAction";
import SmartButton from "./SmartButton";

export type IActionOrComponent = ISmartAction | IRenameComponentProps;

interface IProps {
  actions: IActionOrComponent[];
  accordionOpen?: boolean; // undefined for no accordion (around)
  showSubheader?: boolean; // for accordion only
  showLabels: boolean;
}

function SmartActionBar({
  actions,
  accordionOpen,
  showLabels,
  showSubheader,
}: IProps): JSX.Element | null {
  const toolbar = (
    <Toolbar disableGutters={true} variant="dense">
      <Stack
        direction="row"
        flexWrap="wrap"
        useFlexGap
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        {actions
          .filter((a) => isVisible(a))
          .map((a) => _createEntry(a, showLabels))}
      </Stack>
    </Toolbar>
  );
  if (accordionOpen == null) {
    return toolbar;
  } else {
    const subheaderText =
      showSubheader === false ? undefined : "Aktionen und Werkzeuge";
    return (
      <SmartAccordion
        header="Aktionen"
        subheader={subheaderText}
        defaultExpanded={accordionOpen}
      >
        <SmartActionBar actions={actions} showLabels={showLabels} />
      </SmartAccordion>
    );
  }
}

// Helpers
function _createEntry(aoc: IActionOrComponent, showLabel: boolean) {
  if ("oldName" in aoc && "onNewName" in aoc) {
    const renameComponentProps = aoc;
    return (
      <RenameComponent
        key={"rename-comp"}
        oldName={renameComponentProps.oldName}
        onNewName={renameComponentProps.onNewName}
        disabled={renameComponentProps.disabled}
        text={renameComponentProps.text}
        shortText={renameComponentProps.shortText}
        showLabel={renameComponentProps.showLabel}
        label={renameComponentProps.label}
      />
    );
  } else {
    const action = aoc;
    return (
      <SmartButton
        key={action.text + action.shortText}
        text={action.text}
        shortText={action.shortText}
        selected={action.selected}
        onMouse={action.onMouse}
        tooltipPlacement="bottom"
        value={action.value}
        children={action.children}
        dialogProps={action.dialogProps}
        disabled={action.disabled}
        showLabel={showLabel}
      />
    );
  }
}

export default SmartActionBar;
function isVisible(a: any): boolean {
  if (a.invisible != null) {
    return !a.invisible;
  }
  return true;
}
