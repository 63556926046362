import { skipToken } from "@reduxjs/toolkit/dist/query/index.js";
import { IPlaceAttachment } from "../../AppModelTypes";
import {
  useGetPlaceAttachmentsForPlaceQuery,
  useCreatePlaceAttachmentMutation,
} from "../../api/PlacesApiSlice";

export function usePlaceAttachmentsService(placeId?: string) {
  const { data: placeAttachmentsOfPlaceQueryResult } =
    useGetPlaceAttachmentsForPlaceQuery(placeId ?? skipToken);
  const [_createPlaceAttachment] = useCreatePlaceAttachmentMutation();

    function createPlaceAttachment(url: string) {
      if (url == null) {
        throw new Error("no url provided");
      }
      const data = { 'url': url };
      const newEntry = { PlaceId: placeId, Data: JSON.stringify(data) };
      _createPlaceAttachment(newEntry);
    }

  const ret = {
    placeAttachments: [] as IPlaceAttachment[],
    createPlaceAttachment,
  };

  if (placeAttachmentsOfPlaceQueryResult != null) {
    const records = placeAttachmentsOfPlaceQueryResult.records;
    if (records != null && records.length > 0) {
      ret.placeAttachments = _getPlaceAttachmentsFromRecords(records);
    }
  }

  return ret;
}

function _getPlaceAttachmentsFromRecords(records: any[]): IPlaceAttachment[] {
  const ret = [];
  for (let index = 0; index < records.length; index++) {
    const r = records[index];
    const pa = _getPlaceAttachmentFromRecord(r);
    ret.push(pa);
  }
  return ret;
}
function _getPlaceAttachmentFromRecord(record: any): IPlaceAttachment {
  let ret: IPlaceAttachment = {
    Id: "" + record.Id,
    PlaceId: record.PlaceId,
    Data: JSON.parse(record.Data),
  };
  return ret;
}
