import { Icon } from "leaflet";

export function createCrossIcon() {
  // const iconUrl = "/images/crosshair.svg";
  // const iconUrl = "/images/crosshair_black_fill.svg";
  const iconUrl = "/images/crosshair_white_fill.svg";
  const size = 40;
  const ret = new Icon({
    iconUrl: iconUrl,
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
    // popupAnchor: [0, -40],
  });
  return ret;
}
