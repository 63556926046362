import { Box, Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import React from "react";
import { DRAWER_WIDTH, IMenuEntry, MENU_STRUCTURE, VisibleE } from "../../AppConstants";
import { useUserService } from '../../services/UserService';

// implementation from here: https://www.geeksforgeeks.org/react-mui-drawer-navigation/

interface IProps {
  isOpen: boolean
  toggleDrawer: any
}

function MainDrawer({ isOpen, toggleDrawer }: IProps): JSX.Element | null {
  const { isAuthenticated, isSuperuser } = useUserService();

  const filteredList = MENU_STRUCTURE.filter((x: any) => {
    return (
      x.mainMenu === VisibleE.always ||
      (isAuthenticated && x.mainMenu === VisibleE.if_logged_in) ||
      (isSuperuser && x.mainMenu === VisibleE.if_superuser)
    );
  });
  const drawerContent = <Box
    // sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
    role="presentation"
    onClick={toggleDrawer} //{toggleDrawer(false)}
    onKeyDown={toggleDrawer} //{toggleDrawer(false)}
  >
    <List>
      {filteredList.map((x) => {
        return _createListItem(x);
      })}
    </List>
  </Box>;

  const ret =
    <Box
      component="nav"
      sx={{
        width: { sm: DRAWER_WIDTH },
        flexShrink: { sm: 0 }
      }}
    >
      <Drawer
        variant="temporary"
        open={isOpen}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
          },
        }}
      >
        {drawerContent}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
          },
        }}
        open
      >
        {drawerContent}
      </Drawer>
    </Box>
  return ret;
}

function _createListItem(x: IMenuEntry) {
  return (<ListItem key={x.link}
    disablePadding>
    <ListItemButton component="a" href={"/" + x.link}>
      <ListItemText primary={x.name} />
    </ListItemButton>
  </ListItem>
  );
}


export default MainDrawer;