import { useAuth0 } from "@auth0/auth0-react";
import { skipToken } from "@reduxjs/toolkit/dist/query/index.js";
import { cloneDeep } from "lodash";
import { useEffect } from "react";
import { isString } from "../Utils";
import {
  useCreateUserDataMutation,
  useGetUserDataQuery,
  useUpdateUserDataMutation,
} from "../api/PlacesApiSlice.js";

const SUPERUSERS = ["auth0|4", "google-oauth2|114068310225681830256"];

export function useUserService() {
  const [updateUserData] = useUpdateUserDataMutation();
  const [createUserData] = useCreateUserDataMutation();
  const {
    user,
    isAuthenticated,
    logout,
    loginWithRedirect,
    isLoading,
    handleRedirectCallback,
  } = useAuth0();
  const userId = user ? user.sub : null;
  const su = userId != null && SUPERUSERS.includes(userId);
  const { data: userDataResult } = useGetUserDataQuery(userId ?? skipToken);
  //   {
  //     "records": [
  //         {
  //             "UserId": "google-oauth2|114068310225681830256",
  //             "Data": "{\"k1\":\"value\",\"k2\":1235,\"favoritePlaces\":[1282252,21732,6055]}"
  //         }
  //     ]
  //   }
  const ret = {
    user,
    userId,
    userDataResult,
    userDataObject: null,
    isLoading,
    isAuthenticated,
    isSuperuser: su,
    favoritePlaceIds: [],
    isFavoritePlace,
    addFavoritePlace,
    removeFavoritePlace,
    isServiceReady: false,
    loginWithRedirect,
    handleRedirectCallback,
    logout,
  };

  useEffect(() => {
    if (
      createUserData != null &&
      userId != null &&
      userDataResult != null &&
      userDataResult.records &&
      userDataResult.records.length < 1
    ) {
      const userDataResultClone = cloneDeep(userDataResult);
      const newUserData = {
        UserId: userId,
        Data: JSON.stringify({}),
      };
      userDataResultClone.records.push(newUserData);
      console.log(__filename + " createUserData");
      createUserData(newUserData);
    }
  }, [createUserData, userDataResult, userId]);

  const userDataObject = _fromUserDataResultToDataObject(userDataResult);
  ret.userDataObject = userDataObject;
  if (userDataObject != null) {
    const f = userDataObject.favoritePlaces;
    if (f != null) {
      ret.favoritePlaceIds = f;
    }
  }
  ret.isServiceReady = true;
  return ret;

  //****************
  //Inner Functions
  function isFavoritePlace(placeId: any): boolean {
    if (placeId != null) {
      const favArray = _fromUserDataResultToFavoritePlaceIds(userDataResult);
      if (favArray != null) {
        return favArray.some((f: any) => {
          return f === placeId;
        });
      }
    }
    return false;
  }
  function removeFavoritePlace(placeId: any) {
    const favoritePlaceIds =
      _fromUserDataResultToFavoritePlaceIds(userDataResult);
    const newArray = favoritePlaceIds.filter((a: any) => {
      return a !== placeId;
    });
    const newUserDataResult = _setFavoritePlaceIdsInUserDataResult(
      userDataResult,
      newArray
    );
    updateUserData(newUserDataResult);
  }
  function addFavoritePlace(placeId: any) {
    const favoritePlaceIds =
      _fromUserDataResultToFavoritePlaceIds(userDataResult);
    favoritePlaceIds.push(placeId);
    const newUserDataResult = _setFavoritePlaceIdsInUserDataResult(
      userDataResult,
      favoritePlaceIds
    );
    updateUserData(newUserDataResult);
  }
}

//*********************
// HELPER FUNCTIONS
function _fromUserDataResultToFavoritePlaceIds(userDataResult: {
  records: any[];
}) {
  let ret = [];
  const dataObject = _fromUserDataResultToDataObject(userDataResult);
  if (dataObject != null) {
    const f = dataObject.favoritePlaces;
    if (f != null) {
      ret = f;
    }
  }
  return ret;
}
function _setFavoritePlaceIdsInUserDataResult(
  userDataResult: { records: any[] },
  favortitePlaceIds: any[]
) {
  let ret = userDataResult;
  const dataObject = _fromUserDataResultToDataObject(userDataResult);
  if (dataObject != null) {
    dataObject.favoritePlaces = favortitePlaceIds;
    ret = { ...userDataResult };
    let newFirst = { ...ret.records[0] };
    newFirst.Data = JSON.stringify(dataObject);
    ret.records = [newFirst];
  }
  return ret;
}
function _fromUserDataResultToDataObject(userDataResult: { records: any[] }) {
  if (userDataResult) {
    if (userDataResult.records) {
      if (userDataResult.records[0]) {
        const firstOne = userDataResult.records[0];
        if (firstOne != null) {
          const dataString = firstOne.Data;
          if (isString(dataString)) {
            return JSON.parse(dataString);
          }
        }
      } else {
      }
    }
  }
  return null;
}
