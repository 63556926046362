import { Typography } from "@mui/material";
import React from "react";
import { useUserService } from "../services/UserService";
import PlacelistsForUserComponent from "./../components/placelists/PlacelistForUserComponent";
import { StyledContent } from "./../components/styles/styled.Content";

function MyPlacelistsPage() {
  const { userId, isServiceReady } = useUserService();
  if (!isServiceReady) {
    return <Typography>lädt...</Typography>;
  }

  const placelists = userId && <PlacelistsForUserComponent userId={userId} />;

  return <StyledContent>{placelists}</StyledContent>;
}

export default MyPlacelistsPage;
