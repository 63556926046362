import { MapTwoTone } from "@mui/icons-material";
import React from "react";
import { isJSONString, navigateToUrl } from "../../Utils";
import ISmartAction from "../../components/buttons/ISmartAction";

export function createNavigateToGoogleMapsAction(place: any): ISmartAction {
  type ICache = {
    placeData?: any;
    coordinates?: any;
    coordText?: string;
    tooltipText?: string;
    href?: string;
    isLocationInaccurate?: boolean;
  };

  let cache: ICache = {
    placeData: undefined,
    coordinates: undefined,
    coordText: undefined,
    tooltipText: undefined,
    href: undefined,
    isLocationInaccurate: undefined,
  };

  function getPlaceData() {
    if (cache.placeData == null) {
      cache.placeData = isJSONString(place.Data)
        ? JSON.parse(place.Data)
        : null;
    }
    return cache.placeData;
  }
  function getCoordinates() {
    if (cache.coordinates == null) {
      cache.coordinates = {
        lon: place.Longitude,
        lat: place.Latitude,
      };
    }
    return cache.coordinates;
  }
  function getCoordText() {
    if (cache.coordText == null) {
      const coordinates = getCoordinates();
      cache.coordText = coordinates.lat + ", " + coordinates.lon;
    }
    return cache.coordText;
  }
  function getTooltipText() {
    if (cache.tooltipText == null) {
      cache.tooltipText =
        "Direkt zu Google Maps" +
        (isLocationInaccurate()
          ? " (ungenau, da Anbieter die exakten Koordinaten erst nach Buchung freigibt)"
          : " (" + getCoordText() + ")");
    }
    return cache.tooltipText;
  }
  function getHref() {
    if (cache.href == null) {
      const coordinates = getCoordinates();
      cache.href =
        "https://www.google.com/maps/search/?api=1&query=" +
        coordinates.lat +
        "%2C" +
        coordinates.lon;
    }
    return cache.href;
  }
  function isLocationInaccurate() {
    if (cache.isLocationInaccurate == null) {
      const placeData = getPlaceData();
      cache.isLocationInaccurate =
        placeData && placeData.gps_location === "inaccurate";
    }
    return cache.isLocationInaccurate;
  }

  function getText(): string {
    return getTooltipText();
  }
  function getChildren(): JSX.Element | JSX.Element[] {
    return <MapTwoTone />;
  }

  function handleMouse() {
    const url = getHref();
    if (url != null) {
      navigateToUrl(url);
    }
  }

  return {
    text: getText(),
    children: getChildren(),
    onMouse: handleMouse,
    disabled: getHref() == null,
  };
}
