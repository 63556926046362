import { skipToken } from "@reduxjs/toolkit/dist/query/index.js";
import { IPlace, IPlacelist, IPlacelistUser } from "../AppModelTypes.js";
import {
  useCreatePlacelistMutation,
  useGetPlacelistsForUserQuery,
  useUpdatePlacelistNameMutation,
  useUpdatePlacelistPlacesMutation,
} from "../api/PlacesApiSlice.js";
import { useUserService } from "./UserService";

export function usePlacelistsService(givenUserId?: string) {
  // const [updateUserData] = useUpdateUserDataMutation();
  const [_updatePlacelistName] = useUpdatePlacelistNameMutation();
  const [_updatePlacelistPlaces] = useUpdatePlacelistPlacesMutation();
  const [_createPlacelist] = useCreatePlacelistMutation();
  let { userId } = useUserService();
  if (givenUserId != null) {
    userId = givenUserId;
  }
  const { data: placelistsOfUserQueryResult } = useGetPlacelistsForUserQuery(
    userId ?? skipToken
  );

  let ret = {
    placelists: [] as IPlacelist[],
    getPlacelist,
    updatePlacelistName,
    updatePlacelistPlaces,
    createPlacelist,
    isPlacelistViewer,
    getViewerPlacelists,
    isPlacelistEditor,
    getEditorPlacelists,
  };

  if (placelistsOfUserQueryResult != null) {
    const records = placelistsOfUserQueryResult.records;
    if (records != null && records.length > 0) {
      ret.placelists = _getPlacelistsFromRecords(records);
    }
  }
  return ret;

  // Helper Functions
  function updatePlacelistName(placelistId: string, newName: string) {
    _updatePlacelistName({ placelistId, newName });
  }
  function updatePlacelistPlaces(placelistId: string, placeIds: number[]) {
    const places = JSON.stringify(placeIds);
    _updatePlacelistPlaces({ placelistId, places });
  }
  function createPlacelist(name: string) {
    if (name == null) {
      throw new Error("no name provided");
    }
    const initialUsers = [{ User: userId, Role: "editor" }];
    const newEntry = { Name: name, Users: JSON.stringify(initialUsers) };
    _createPlacelist(newEntry);
  }
  function getPlacelist(placelistId: string) {
    const allPlacelists = ret.placelists;
    for (let index = 0; index < allPlacelists.length; index++) {
      const pl = allPlacelists[index];
      if (pl.Id === placelistId) {
        return pl;
      }
    }
  }
  function isPlacelistEditor(placelistId: string) {
    return _getPlacelistUserRole(placelistId) === "editor";
  }
  function getEditorPlacelists() {
    const r = [];
    for (let i = 0; i < ret.placelists.length; i++) {
      const pl = ret.placelists[i];
      if (isPlacelistEditor(pl.Id)) {
        r.push(pl);
      }
    }
    return r;
  }
  function isPlacelistViewer(placelistId: string) {
    return _getPlacelistUserRole(placelistId) === "viewer";
  }
  function getViewerPlacelists() {
    const r = [];
    for (let i = 0; i < ret.placelists.length; i++) {
      const pl = ret.placelists[i];
      if (isPlacelistViewer(pl.Id)) {
        r.push(pl);
      }
    }
    return r;
  }
  function _getPlacelistUserRole(placelistId: string) {
    const pl = getPlacelist(placelistId);
    if (pl) {
      const users = pl.Users;
      for (let index = 0; index < users.length; index++) {
        const user = users[index];
        return user.Role;
      }
    }
    return null;
  }
}

// Helper Functions
function _getPlacelistsFromRecords(records: any[]): IPlacelist[] {
  let ret = [];
  for (let i = 0; i < records.length; i++) {
    const r = records[i];
    const pl = _getPlacelistFromRecord(r);
    ret.push(pl);
  }
  return ret;
}

function _getPlacelistFromRecord(record: any): IPlacelist {
  let ret: IPlacelist = {
    Id: "" + record.Id,
    Name: record.Name,
    Places: _getPlacesFromRecord(record),
    Data: JSON.parse(record.Data),
    Users: _getPlacelistUsersFromUserJson(record.Users),
  };
  return ret;
}

function _getPlacesFromRecord(record: any): IPlace[] {
  const ret: IPlace[] = [];
  const placesParsed = JSON.parse(record.Places);
  if (placesParsed != null) {
    for (let i = 0; i < placesParsed.length; i++) {
      const p = placesParsed[i];
      const place: IPlace = { Id: "" + p };
      ret.push(place);
    }
  }
  return ret;
}

function _getPlacelistUsersFromUserJson(Users: any): IPlacelistUser[] {
  let ret: IPlacelistUser[] = [];
  const uList = JSON.parse(Users);
  for (let i = 0; i < uList.length; i++) {
    const u = uList[i];
    const uObject = {
      UserId: u.User,
      Role: u.Role,
    };
    ret.push(uObject);
  }
  return ret;
}
