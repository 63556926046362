import React from "react";
import usePlaceService from "../../services/PlaceService";
import PlaceLarge from "./PlaceLarge";

type IParams = {
  placeId: string;
  showTitle?: boolean;
};

function PlaceFromId({ placeId, showTitle }: IParams) {
  if (showTitle === undefined) {
    showTitle = true;
  }
  const { placeObject } = usePlaceService(placeId);

  return (
    placeObject && (
      <PlaceLarge placeObject={placeObject} showTitle={showTitle} />
    )
  );
}

export default PlaceFromId;
