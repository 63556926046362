import { AttachFileTwoTone } from "@mui/icons-material";
import { Badge, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IPlace } from "../../AppModelTypes";
import { equalContent, isValidUrl, parseJsonIfNeeded } from "../../Utils";
import PlaceAttachmentsForPlace from "../../components/attachments/PlaceAttachmentsForPlace";
import ISmartAction from "../../components/buttons/ISmartAction";
import { IForm } from "../../components/forms/IForm";

function DialogContent(props: IForm): React.JSX.Element {
  const [text, setText] = useState<string>("");
  const place = props.data.place;
  const placeId = place.Id;

  useEffect(() => {
    if (props.onDataChange != null) {
      const data = { name: text };
      props.onDataChange(data);
    }
  }, [text, props]);

  useEffect(() => {
    function checkIfAttachmentAlreadyExists(text: string) {
      const placeAttachments = place.PlaceAttachments;
      if (placeAttachments != null) {
        for (let index = 0; index < placeAttachments.length; index++) {
          const pa = placeAttachments[index];
          const paData = parseJsonIfNeeded(pa.Data);
          if (equalContent(paData.url, text)) {
            return true;
          }
        }
      }
      return false;
    }
    if (props.onError != null) {
      if (text == null || text.length < 1) {
        props.onError("Bitte eine Url eingeben");
      } else if (!isValidUrl(text)) {
        props.onError("Bitte eine gültige Url eingeben");
      } else if (checkIfAttachmentAlreadyExists(text)) {
        props.onError("Anhang existiert bereits. Bitte eine neue Url eingeben");
      } else {
        props.onError(null);
      }
    }
  }, [text, props, place.PlaceAttachments]);

  const content = (
    <>
      <PlaceAttachmentsForPlace placeId={placeId} />
      <TextField
        label="Url"
        defaultValue={text}
        margin="dense"
        autoFocus={true}
        fullWidth
        onChange={(e) => {
          setText(e.target.value);
        }}
      />
    </>
  );
  return content;
}

function createAddAttachmentAction(
  place: IPlace,
  addAttachment: (arg0: string) => void
): ISmartAction {
  function handleDialogOk(data: any) {
    const name: string = data.name;
    addAttachment(name);
  }
  function _contentCreator(props: IForm) {
    const content = (
      <DialogContent
        data={{
          place,
        }}
        onDataChange={props.onDataChange}
        onError={props.onError}
      />
    );
    return { content };
  }

  let badgeContent = 0;
  if (place && place.PlaceAttachments) {
    badgeContent = place.PlaceAttachments.length;
  }
  const iconWithBadge = (
    <Badge
      badgeContent={badgeContent}
      color="success"
      max={9}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <AttachFileTwoTone />
    </Badge>
  );
  const ret: ISmartAction = {
    text: "Anhang hinzufügen",
    shortText: "Anhang...",
    children: iconWithBadge,
    dialogProps: {
      title: "Anhang hinzufügen",
      onCreateContent: _contentCreator,
      onOk: handleDialogOk,
    },
  };
  return ret;
}

export default createAddAttachmentAction;
