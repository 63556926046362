import {
  ButtonGroup,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { isJSONString, isNullOrUndefined } from "../../Utils";
import { INITIAL_PROVIDER_OPTIONS, MAX_PRICE_OPTIONS } from "./PlacesConstants";
import PlacesResultList from "./PlacesResultList";

export function PlacesListWithFilter() {
  const storedCountriesString = localStorage.getItem("countries");
  const storedCountries = JSON.parse(storedCountriesString);
  const [countries] = useState(storedCountries ? storedCountries : []);
  const storedShowPlacesWithoutAttachment = localStorage.getItem(
    "showPlacesWithoutAttachment"
  );
  const [showPlacesWithoutAttachment] = useState(
    storedShowPlacesWithoutAttachment !=null
      ? JSON.parse(storedShowPlacesWithoutAttachment)
      : true
  );
  const storedShowPlacesWithoutPriceInfo = localStorage.getItem(
    "showPlacesWithoutPriceInfo"
  );
  const [showPlacesWithoutPriceInfo] = useState(
    storedShowPlacesWithoutPriceInfo !=null
      ? JSON.parse(storedShowPlacesWithoutPriceInfo)
      : true
  );
  const storedMaxPrice = localStorage.getItem("maxPrice");
  const [maxPrice, setMaxPrice] = useState(
    storedMaxPrice
      ? storedMaxPrice
      : MAX_PRICE_OPTIONS[MAX_PRICE_OPTIONS.length - 1].value
  );
  const storedProviders = localStorage.getItem("providers");
  const [providers] = useState(
    storedProviders && isJSONString(storedProviders)
      ? JSON.parse(storedProviders)
      : INITIAL_PROVIDER_OPTIONS
  );
  const [searchString, setSearchString] = useState("");
  const [queryProps, setQueryProps] = useState({});

  useEffect(() => {
    localStorage.setItem("maxPrice", maxPrice);
    const props = {
      searchString: searchString,
      countries: countries,
      showPlacesWithoutAttachment,
      showPlacesWithoutPriceInfo,
      maxPrice: maxPrice,
      providers: providers,
      joinScrapeLinks: true,
    };
    setQueryProps(props);
  }, [
    searchString,
    showPlacesWithoutAttachment,
    showPlacesWithoutPriceInfo,
    maxPrice,
    providers,
    countries,
  ]);

  let list = <Typography>Laden...</Typography>;
  if (!isNullOrUndefined(queryProps) && Object.keys(queryProps).length > 0) {
    list = <PlacesResultList queryArguments={queryProps} />;
  }

  return (
    <>
      <ButtonGroup>
        <TextField
          id="outlined-basic"
          label="im Namen suchen"
          value={searchString}
          type="search"
          onChange={(e) => setSearchString(e.target.value)}
        />{" "}
        <FormControl fullWidth>
          <InputLabel id="select-maxPrice-label">Maximale Preis</InputLabel>
          <Select
            labelId="select-maxPrice-label"
            id="select-maxPrice"
            value={maxPrice}
            label="Maximale Preis"
            onChange={(e) => setMaxPrice(e.target.value)}
          >
            {MAX_PRICE_OPTIONS &&
              MAX_PRICE_OPTIONS.map((o) => (
                <MenuItem key={o.key} value={o.value}>
                  {o.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </ButtonGroup>
      {list}
    </>
  );
}
