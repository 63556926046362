import React, { useContext, useEffect, useState } from "react";
import { IPlacelist } from "../../AppModelTypes";
import usePlacelistActions from "../../actions/placelist/PlacelistActions";
import usePlacesService from "../../services/PlacesService";
import SmartActionBar from "../buttons/SmartActionBar";
import SmartMap from "../maps/SmartMap";
import SmartAccordion from "../accordion/SmartAccordion";
import PlacesList from "../places/PlacesList";
import { TitleContext } from "./../../TitleContext";
import PlacelistUsersComponent from "./PlacelistUsersComponent";

interface IProps {
  placelist: IPlacelist;
}

function PlacelistComponentLarge({ placelist }: IProps): JSX.Element | null {
  const [title, setTitle] = useState<String | undefined>(undefined);
  const titleContext = useContext(TitleContext);
  const places = placelist.Places;
  const placeIds: string[] = [];
  if (places != null) {
    // subheaderText = "Placelist mit " + places.length + " Plätzen";
    for (let index = 0; index < places.length; index++) {
      const p = places[index];
      const id = p.Id;
      placeIds.push(id);
    }
  }
  const { placeObjects } = usePlacesService(placeIds);

  let thePlaces = null;
  if (placeObjects) {
    let headerText = "Plätze";
    const placeCount = placeObjects.length;
    if (placeCount === 0) {
      headerText = "keine Plätze";
    } else if (placeCount === 1) {
      headerText = "Einen Platz";
    } else if (placeCount > 1) {
      headerText = placeCount + " Plätze";
    }
    thePlaces = (
      <SmartAccordion header={headerText} defaultExpanded={true}>
        <PlacesList key="place_list" places={placeObjects} />
      </SmartAccordion>
    );
  }

  useEffect(() => {
    if (placelist != null) {
      if (placelist.Name != null) {
        setTitle(placelist.Name);
      }
    }
  }, [placelist]);

  useEffect(() => {
    if (title) {
      if (titleContext) {
        titleContext.setTitle(title);
      }
      // window.document.title = title;
    }
  }, [title, titleContext]);

  const theMap = (
    <SmartAccordion header="Karte">
      <SmartMap places={placeObjects} />
    </SmartAccordion>
  );
  const actions = usePlacelistActions(undefined, placelist).onePlacelistActions;
  const actionbar = (
    <SmartActionBar actions={actions} accordionOpen={true} showLabels={true} />
  );

  const ret = (
    <>
      {theMap}
      {actionbar}
      {thePlaces}
      <PlacelistUsersComponent key="pluc" placelist={placelist} />
    </>
  );
  return ret;
}

export default PlacelistComponentLarge;
