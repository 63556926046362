import { ShareTwoTone } from "@mui/icons-material";
import { Stack } from "@mui/material";
import React from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { APP_NAME } from "../../AppConstants";
import { IPlace } from "../../AppModelTypes";
import ISmartAction from "../../components/buttons/ISmartAction";
import SmartTooltip from "../../components/others/SmartTooltip";
import { IForm } from "../../components/forms/IForm";
import { IDialogProps } from "../../components/dialogs/IDialogProps";

const ICON_SIZE = 24;
const ICON_ROUNDED = true;

function createSharePlaceAction(
  place: IPlace,
  title?: string,
  url?: string
): ISmartAction {
  let shareUrl = window.location.origin;
  if (url != null) {
    shareUrl = url;
  } else {
    if (place != null) {
      const pid = place.Id;
      shareUrl = window.location.origin + "/place/" + pid;
    }
  }
  let subject = "Place23";
  if (title != null) {
    subject = title;
  } else if (place != null && place.Name != null) {
    subject = "Stellplatz bei " + APP_NAME + ": " + place.Name;
  }
  const whatsApp = (
    <SmartTooltip title="Teilen mit WhatsApp">
      <WhatsappShareButton title={subject} url={shareUrl}>
        <WhatsappIcon size={ICON_SIZE} round={ICON_ROUNDED} />
      </WhatsappShareButton>
    </SmartTooltip>
  );
  const facebook = (
    <SmartTooltip title="Teilen mit Facebook">
      <FacebookShareButton quote={subject} url={shareUrl}>
        <FacebookIcon size={ICON_SIZE} round={ICON_ROUNDED} />
      </FacebookShareButton>
    </SmartTooltip>
  );
  const twitter = (
    <SmartTooltip title="Teilen mit Twitter">
      <TwitterShareButton title={subject} url={shareUrl}>
        <TwitterIcon size={ICON_SIZE} round={ICON_ROUNDED} />
      </TwitterShareButton>
    </SmartTooltip>
  );
  const linkedin = (
    <SmartTooltip title="Teilen mit LinkedIn">
      <LinkedinShareButton title={subject} source={shareUrl} url={shareUrl}>
        <LinkedinIcon size={ICON_SIZE} round={ICON_ROUNDED} />
      </LinkedinShareButton>
    </SmartTooltip>
  );
  const email = (
    <SmartTooltip title="Teilen per E-Mail">
      <EmailShareButton subject={subject} url={shareUrl}>
        <EmailIcon size={ICON_SIZE} round={ICON_ROUNDED} />
      </EmailShareButton>
    </SmartTooltip>
  );

  function _contentCreator(props: IForm) {
    const content = (
      <Stack
        direction="row"
        flexWrap="wrap"
        useFlexGap
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ px: 1, pt: 1, pb: 0.5 }}
      >
        {whatsApp}
        {facebook}
        {twitter}
        {linkedin}
        {email}
      </Stack>
    );
    return { content };
  }
  function handleDialogOk(data: any) {}

  const dialogProps: IDialogProps = {
    title: "Platz teilen",
    onCreateContent: _contentCreator,
    onOk: handleDialogOk,
  };

  return {
    text: "Teilen",
    // onMouse: handleClick,
    children: <ShareTwoTone />,
    dialogProps,
  };
}

export default createSharePlaceAction;
