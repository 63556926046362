import { useGetPlacesJoinFilteredQuery } from "../api/PlacesApiSlice";

function usePlacesService(placeIds: string[]) {
  let skip = false;
  if (placeIds == null || placeIds.length < 1) {
    skip = true;
  }
  for (let index = 0; index < placeIds.length; index++) {
    placeIds[index] = "" + placeIds[index];
  }
  const queryPros = {
    ids: placeIds,
  };
  const { data: placeQueryResult } = useGetPlacesJoinFilteredQuery(queryPros, {
    skip,
  });
  let placeObjects = [];
  if (placeQueryResult) {
    if (placeQueryResult.records && placeQueryResult.records[0]) {
      placeObjects = placeQueryResult.records;
    }
  }
  const ret = { placeObjects };
  return ret;
}

export default usePlacesService;
