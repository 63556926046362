import { Typography } from "@mui/material";
import React from "react";
import { IPlacelist } from "../../AppModelTypes";
import usePlacelistActions from "../../actions/placelist/PlacelistActions";
import { usePlacelistsService } from "../../services/PlacelistsService";
import SmartActionBar from "../buttons/SmartActionBar";
import PlacelistComponentSmall from "./PlacelistComponentSmall";

interface IProps {
  userId?: string;
}

function PlacelistsForUserComponent({ userId }: IProps): JSX.Element | null {
  let listOfPlacelists = <Typography>Keine Placelist vorhanden</Typography>;
  const { placelists } = usePlacelistsService(userId);
  if (placelists != null && placelists.length > 0) {
    listOfPlacelists = (
      <>
        {placelists.map((placelist: IPlacelist) => {
          return (
            <PlacelistComponentSmall key={placelist.Id} placelist={placelist} />
          );
        })}
      </>
    );
  }

  const { overPlacelistActions } = usePlacelistActions();
  const actionbar = (
    <SmartActionBar
      accordionOpen={true}
      actions={overPlacelistActions}
      showLabels={true}
    />
  );
  const ret = (
    <>
      {actionbar}
      {listOfPlacelists}
    </>
  );

  return ret;
}

export default PlacelistsForUserComponent;
