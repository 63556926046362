import React from "react";
import { isNullOrUndefined } from "../../Utils";
import SmartAttribute from "./SmartAttribute";

interface IProps {
  dataObject: any
  attributeKey: string
  label?: string
  formatValue?: (value: any) => string
}

function ObjectAttributeComponent({ dataObject, attributeKey, label, formatValue }: IProps): JSX.Element | null {
  let ret = null;
  if (dataObject) {
    if (attributeKey && attributeKey.length > 0) {
      const attributeValue = dataObject[attributeKey];
      let value = attributeValue;
      if (!!formatValue) {
        value = formatValue(value);
      }
      if (isNullOrUndefined(value)) {
        value = "-";
      }
      if (label === undefined) {
        label = attributeKey;
      }
      ret = <SmartAttribute key={label} label={label} value={value} />;
    }
  }
  return ret;
}

export default ObjectAttributeComponent;