import { AddCardTwoTone } from "@mui/icons-material";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { IPlace, IPlacelist } from "../../AppModelTypes";
import ISmartAction from "../../components/buttons/ISmartAction";
import { cloneDeep } from "lodash";
import { IForm } from "../../components/forms/IForm";

function ChoosePlacelistDialogContent(props: IForm): React.JSX.Element {
  let content = <Typography>Keine Placelists verfügbar.</Typography>;
  const data = props.data;
  const initModel: { pl: IPlacelist; selected: boolean }[] = [];
  if (data != null) {
    for (let i = 0; i < data.length; i++) {
      const pl: IPlacelist = data[i];
      initModel.push({ pl, selected: false });
    }
  }
  const [model, setModel] = useState(initModel);

  useEffect(() => {
    if (props.onDataChange != null) {
      props.onDataChange(model);
    }
    if (props.onError != null) {
      let err = null;
      let selectCount = 0;
      for (let index = 0; index < model.length; index++) {
        const element = model[index];
        if (element.selected === true) {
          selectCount++;
        }
      }
      if (selectCount < 1) {
        err = "Keine Placelist selektiert.";
      }
      props.onError(err);
    }
  }, [model, props]);

  if (data != null) {
    const tempModel = cloneDeep(model);
    content = (
      <FormGroup>
        {tempModel.map((o) => {
          const pl: IPlacelist = o.pl;
          function handlChange(
            event: ChangeEvent<HTMLInputElement>,
            checked: boolean
          ): void {
            o.selected = checked;
            setModel(tempModel);
          }
          return (
            <FormControlLabel
              key={pl.Id}
              control={<Checkbox key={pl.Id} onChange={handlChange} />}
              label={pl.Name}
            />
          );
        })}
      </FormGroup>
    );
  }
  return content;
}

// --------------------   create Action   ------------------------------------------

function createAddToPlacelistAction(
  place: IPlace,
  getEditorPlacelists: { (): IPlacelist[]; (): any[] },
  getPlacelist: (placelistId: string) => IPlacelist | undefined,
  updatePlacelistPlaces: {
    (placelistId: string, placeIds: number[]): void;
    (arg0: string, arg1: number[]): void;
  }
): ISmartAction {
  const pid = place ? place.Id : null;

  function handleDialogOk(data: any) {
    // console.log("handleDialogOk(data: any)");
    // console.dir(data);
    if (pid == null) {
      return;
    }
    const toAddModel = data.filter((o: { selected: boolean }) => {
      return o.selected === true;
    });
    for (let i = 0; i < toAddModel.length; i++) {
      const element = toAddModel[i];
      const pl: IPlacelist = element.pl;
      const places = pl.Places;
      const arrayOfPlaceIds: number[] = [];
      if (places != null) {
        for (let j = 0; j < places.length; j++) {
          const place = places[j];
          arrayOfPlaceIds.push(+place.Id); // + makes a number
        }
      }
      arrayOfPlaceIds.push(+pid);
      console.dir(arrayOfPlaceIds);
      updatePlacelistPlaces(pl.Id, arrayOfPlaceIds);
    }
  }

  function _contentCreator(props: IForm) {
    let content = null;
    if (pid != null) {
      const availablePlacelistsToAdd = getEditorPlacelists().filter(
        (pl: IPlacelist) => {
          return !_isPlaceInPlacelist(pid, pl);
        }
      );
      content = (
        <ChoosePlacelistDialogContent
          data={availablePlacelistsToAdd}
          onDataChange={props.onDataChange}
          onError={props.onError}
        />
      );
    }
    return { content };
  }

  return {
    text: "Zu Placelist hinzufügen",
    children: <AddCardTwoTone />,
    value: place.Id,
    dialogProps: {
      title: "Platz zu Placelist hinzufügen",
      onCreateContent: _contentCreator,
      onOk: handleDialogOk,
    },
  };
}

export default createAddToPlacelistAction;

function _isPlaceInPlacelist(placeId: string, pl: IPlacelist): boolean {
  if (pl && pl.Places) {
    for (let i = 0; i < pl.Places.length; i++) {
      const placeInPlacelist = pl.Places[i];
      if (placeInPlacelist.Id + "" === placeId + "") {
        return true;
      }
    }
  }
  return false;
}
