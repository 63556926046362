import { Typography } from "@mui/material";
import React from "react";
import PlaceAttachmentComponent from "./PlaceAttachmentComponent";
import usePlaceService from "./../../services/PlaceService";

interface IProps {
  placeId: string;
  showHeader?: boolean;
}

function PlaceAttachmentsForPlace({
  placeId,
  showHeader,
}: IProps): JSX.Element | null {
  const { placeObject } = usePlaceService(placeId);
  let scrapeLinks = [];
  if (placeObject && placeObject.ScrapeLinks) {
    scrapeLinks = placeObject.ScrapeLinks;
  }
  let placeAttachments = [];
  if (placeObject && placeObject.PlaceAttachments) {
    placeAttachments = placeObject.PlaceAttachments;
  }
  if (showHeader == null) {
    showHeader = true;
  }
  let ret = null;
  if (scrapeLinks || placeAttachments) {
    let header = null;
    if (showHeader === true) {
      let headerText = "Anhänge";
      const count = placeAttachments.length + scrapeLinks.length;
      if (count === 0) {
        headerText = "keine Anhänge";
      } else if (count === 1) {
        headerText = "Einen Anhang";
      } else if (count > 1) {
        headerText = count + " Anhänge";
      }
      header = <Typography>{headerText}</Typography>;
    }
    ret = (
      <>
        {header}
        {/* {scrapeLinks.map((pl: any) => {
          return (
            <SmartAttribute
              key={"pl-" + pl.Id}
              label="Direktlink"
              value={pl.Url}
              showPreview={false}
            />
          );
        })} */}
        {placeAttachments.map((pa: any) => {
          return (
            <PlaceAttachmentComponent
              key={"pa-" + pa.Id}
              placeAttachment={pa}
            />
          );
        })}
      </>
    );
  }
  return ret;
}

export default PlaceAttachmentsForPlace;
