import { FavoriteBorderTwoTone, FavoriteTwoTone } from "@mui/icons-material";
import { red } from "@mui/material/colors";
import React from "react";
import { IPlace } from "../../AppModelTypes";
import ISmartAction from "../../components/buttons/ISmartAction";

function createFavoritePlaceAction(
  placeObject: IPlace,
  userService: any
): ISmartAction {
  const placeId = placeObject.Id;
  const {
    isAuthenticated,
    removeFavoritePlace,
    isFavoritePlace,
    addFavoritePlace,
  } = userService;
  const isFavorite: boolean = isFavoritePlace(placeId);

  function getText() {
    if (isFavorite === true) {
      return "Favorit entfernen";
    } else {
      return "Favorit hinzufügen";
    }
  }
  function getIcon() {
    if (isFavorite) {
      return <FavoriteTwoTone sx={{ color: red[500] }} />;
    } else {
      return <FavoriteBorderTwoTone />;
    }
  }
  function _handleFavoriteMouseButtonClick() {
    if (placeId != null) {
      if (isFavorite) {
        removeFavoritePlace(placeId);
      } else {
        addFavoritePlace(placeId);
      }
    }
  }

  return {
    text: getText(),
    children: getIcon(),
    onMouse: _handleFavoriteMouseButtonClick,
    disabled: placeObject == null || !isAuthenticated,
    invisible: !isAuthenticated,
  };
}

export default createFavoritePlaceAction;
