import { Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { IPlace } from "../../AppModelTypes";
import { TitleContext } from "../../TitleContext";
import { isNullOrUndefined, numberToMoney } from "../../Utils";
import usePlaceActions from "../../actions/place/PlaceActions";
import { useGetScrapeLinksQuery } from "../../api/PlacesApiSlice";
import PlaceAttachmentsForPlace from "../attachments/PlaceAttachmentsForPlace";
import SmartActionBar from "../buttons/SmartActionBar";
import LeafletImage from "../maps/LeafletImage";
import GenericInfoComponent from "../others/GenericInfoComponent";
import ObjectAttributeComponent from "../others/ObjectAttributeComponent";
import NearbyPlacesRadiusComponent from "../places/NearbyPlacesRadiusComponent";
import ScrapeLinksList from "./parts/ScrapeLinksList";
import PlacelistsWithPlace from "../placelists/PlacelistsWithPlace";

type IProps = {
  placeObject: IPlace;
  showTitle?: boolean;
  hideFlyTo?: boolean;
};

export default function PlaceLarge({
  placeObject,
  showTitle = true,
  hideFlyTo,
}: IProps) {
  const placeId = placeObject.Id;
  const { onePlaceActions } = usePlaceActions(placeId, hideFlyTo);
  const { data: scrapeLinksQueryResult } = useGetScrapeLinksQuery(placeId);
  const [scrapeLinks, setScrapeLinks] = useState<any[]>([]);
  const [title, setTitle] = useState<String | undefined>(undefined);
  const [MapImageComponent, setMapImageComponent] = useState<any>(null);
  const [nearbyComponent, setNearbyComponent] = useState<any>(null);
  const [placelistsWithPlaceComp, setPlacelistsWithPlaceComp] =
    useState<any>(null);
  const titleContext = useContext(TitleContext);

  useEffect(() => {
    if (!isNullOrUndefined(placeObject)) {
      if (!isNullOrUndefined(placeObject.Name)) {
        setTitle(placeObject.Name);
      }
      const lat = placeObject.Latitude;
      const lon = placeObject.Longitude;
      if (lat != null && lon != null) {
        const leafletImage = (
          <LeafletImage
            latitude={lat}
            longitude={lon}
            zoom={15}
            width="300px"
            height="300px"
            showCenterMarker={true}
          />
        );
        setMapImageComponent(leafletImage);
        const nbc = <NearbyPlacesRadiusComponent place={placeObject} />;
        setNearbyComponent(nbc);
        const plwp = (
          <PlacelistsWithPlace
            key={"PlacelistsWithPlace_" + placeObject.Id}
            place={placeObject}
          />
        );
        setPlacelistsWithPlaceComp(plwp);
      }
    }
  }, [placeObject]);

  useEffect(() => {
    if (title) {
      if (titleContext) {
        titleContext.setTitle(title);
      }
      // window.document.title = title;
    }
  }, [title, titleContext]);

  useEffect(() => {
    if (scrapeLinksQueryResult) {
      const records = scrapeLinksQueryResult.records;
      setScrapeLinks(records);
    }
  }, [scrapeLinksQueryResult]);

  let titleComponent = null;
  if (showTitle) {
    titleComponent = isNullOrUndefined(title) ? (
      <Typography variant="h3"></Typography>
    ) : (
      <Typography variant="h3">{title}</Typography>
    );
  }

  let scrapeLinksComponent = null;
  if (localStorage.getItem("info") === "true") {
    scrapeLinksComponent = <ScrapeLinksList scrapeLinks={scrapeLinks} />;
  }

  const actionBar = (
    <SmartActionBar actions={onePlaceActions} showLabels={false} />
  );

  return (
    <>
      <Stack key={"Stack" + placeId} spacing={1}>
        {showTitle ? titleComponent : null}
        <Stack
          key="info"
          direction="row"
          spacing={{ xs: 1, sm: 2 }}
          flexWrap="wrap"
          useFlexGap
        >
          {MapImageComponent}
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            flexGrow={1}
            useFlexGap
          >
            <ObjectAttributeComponent
              key="Land"
              dataObject={placeObject}
              attributeKey="Land"
            />
            {/* <ObjectAttributeComponent
              key="PreisOriginal"
              dataObject={placeObject}
              attributeKey="PreisOriginal"
              label="Preisangabe des Anbieters"
            /> */}
            <ObjectAttributeComponent
              key="Preis"
              dataObject={placeObject}
              attributeKey="Preis"
              formatValue={(v) => numberToMoney(v)}
            />
            {placeId && (
              <PlaceAttachmentsForPlace
                key="place-attachments"
                placeId={placeId}
                showHeader={false}
              />
            )}
          </Stack>
        </Stack>
        {actionBar}
        {nearbyComponent}
        {placelistsWithPlaceComp}
        {scrapeLinksComponent}
        <GenericInfoComponent
          key={"GenericInfoComponent_" + placeId}
          dataObject={placeObject}
          hide={true}
        />
      </Stack>
    </>
  );
}
