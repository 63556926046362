import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import MatGeocoder from "react-mui-mapbox-geocoder";
import { isDebugInfo } from "../../AppUtils";
import GenericInfoComponent from "../others/GenericInfoComponent";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoicGhvdG9icmFuZHkiLCJhIjoiY2xpdTFodHJ4MDN2YjNjbzJjcWJtb3lxYiJ9.yBWSIIo8ipDmS9AIs7e40Q";

export interface ICoordinates {
  latitude: number
  longitude: number
}

interface IProps {
  onSelect?: any
  onCoordinates?: any
}

function GeoSearchComponent({ onSelect, onCoordinates }: IProps): JSX.Element | null {
  const [selected, setSelected] = React.useState<any>(null);
  const [selectedText, setSelectedText] = React.useState("");
  const [selectedCoordinates, setSelectedCoordinates] = React.useState<ICoordinates | null>(null);
  const geocoderApiOptions = {};

  useEffect(() => {
    if (onSelect != null) {
      onSelect(selected);
    }
    if (selected) {
      if (selected.place_name) {
        setSelectedText(selected.place_name);
      }
      if (selected.geometry && selected.geometry.coordinates) {
        const cArray = selected.geometry.coordinates;
        const coords: ICoordinates = { longitude: cArray[0], latitude: cArray[1] }
        setSelectedCoordinates(coords);
      }
    } else {
      setSelectedText("");
      setSelectedCoordinates(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (onCoordinates != null) {
      onCoordinates(selectedCoordinates);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoordinates]);

  function _getCoordsText(): string {
    let ret: string = "";
    if (selectedCoordinates) {
      const coords: ICoordinates = selectedCoordinates;
      const lat = coords.latitude;
      const long = coords.longitude;
      if (lat && long) {
        ret = long + " / " + lat + " (Longitude/Latitude)";
      }
    }
    return ret;
  }
  function handleSelect(p: any) {
    setSelected(p);
  }
  function handleSuggest(p: any) {
  }
  function handleInputClear() {
    setSelected(null);
  }

  return <>
    <MatGeocoder
      inputPlaceholder="Ort suchen"
      accessToken={MAPBOX_TOKEN}
      onSelect={handleSelect}
      onSuggest={handleSuggest}
      showLoader={true}
      language="de"
      onInputClear={handleInputClear}
      {...geocoderApiOptions} />
    <>
      <Typography variant="h6" display="block" gutterBottom>{selectedText}</Typography>
      <Typography gutterBottom>{_getCoordsText()}</Typography>
      {selected != null && isDebugInfo() && <GenericInfoComponent dataObject={selected} open={false} />}
    </>
  </>;
}

export default GeoSearchComponent;