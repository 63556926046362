import { Card, CardContent, CardHeader } from "@mui/material";
import React from "react";
import { IPlacelist } from "../../AppModelTypes";
import usePlacelistActions from "../../actions/placelist/PlacelistActions";
import SmartActionBar from "../buttons/SmartActionBar";
import SmartAccordion from "../accordion/SmartAccordion";
import PlacesList from "../places/PlacesList";
import SmartCardActions from "./../others/SmartCardActions";

interface IProps {
  placelist: IPlacelist;
  showPlaces?: boolean;
}

function PlacelistComponentSmall({
  placelist,
  showPlaces,
}: IProps): JSX.Element | null {
  if (showPlaces == null) {
    showPlaces = true;
  }
  let subheaderText = "Placelist";
  const places = placelist.Places;
  if (places != null) {
    // subheaderText = "Placelist mit " + places.length + " Plätzen";
  }

  let thePlaces = null;
  if (places) {
    let headerText = "Plätze";
    const placeCount = places.length;
    if (placeCount === 0) {
      headerText = "keine Plätze";
    } else if (placeCount === 1) {
      headerText = "Einen Platz";
    } else if (placeCount > 1) {
      headerText = placeCount + " Plätze";
    }
    thePlaces = (
      <SmartAccordion header={headerText}>
        <PlacesList key="place_list" places={places} />
      </SmartAccordion>
    );
  }
  const { onePlacelistActions } = usePlacelistActions(undefined, placelist);
  const actionBar = (
    <SmartActionBar actions={onePlacelistActions} showLabels={false} />
  );

  const ret = (
    <Card
      raised={true}
      elevation={5}
      id={placelist.Id}
      sx={{ m: 1.5, borderRadius: 2 }}
    >
      <CardHeader
        title={placelist.Name}
        sx={{ pb: 0 }}
        subheader={subheaderText}
      ></CardHeader>
      {showPlaces && (
        <CardContent sx={{ pb: 0 }}>
          {thePlaces}
          {/* {isDebugInfo() ? <GenericInfoComponent key="generic" dataObject={placelist} /> : null} */}
        </CardContent>
      )}
      <SmartCardActions>{actionBar}</SmartCardActions>
    </Card>
  );
  return ret;
}

export default PlacelistComponentSmall;
