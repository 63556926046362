import { List, ListItem, ListItemText } from "@mui/material";
import { isObject } from "lodash";
import React from "react";
import { isJSONString } from "../../Utils";
import SmartAccordion from "../accordion/SmartAccordion";
import SmartAttribute from "./SmartAttribute";

type IProps = {
  dataObject: any;
  hide?: boolean; // not visible if true
  open?: boolean; // open means expanded
};

function GenericInfoComponent({
  dataObject,
  hide,
  open,
}: IProps): JSX.Element | null {
  if (dataObject && dataObject.Name === "Extrem gute Stellplätze") {
    console.log("GenericInfoComponent");
    console.dir(dataObject);
  }
  if (hide === true) {
    if (localStorage.getItem("info") !== "true") {
      return null;
    }
  }
  const ret = _createUI(dataObject, open);
  return ret;
}

function _createUI(dataObject: any, open: boolean | undefined) {
  let retList: any[] = [];
  let summaryText = "Eigenschaften";
  if (dataObject) {
    const p = _processDataObject(dataObject, 0);
    retList = p.retList;
    summaryText = p.summaryText;
  }

  const infoOpen = open ? open : false;
  return (
    <SmartAccordion header={summaryText} defaultExpanded={infoOpen}>
      <List component="nav" dense={true} key="outer">
        {retList}
      </List>
    </SmartAccordion>
  );
}

function _processDataObject(dataObject: any, indent: number) {
  const retList = [];
  let summaryText = "";
  const keys = Object.keys(dataObject);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = dataObject[key];
    const isJson = isJSONString(value);
    if (typeof value === "boolean") {
      const valueText = Boolean(value).toString();
      const leftIndent = 4 * indent;
      retList.push(
        <ListItem key={key} sx={{ pl: leftIndent }}>
          {_createAttributeComponent(key, valueText)}
        </ListItem>
      );
    } else if (
      Array.isArray(value) &&
      value.every((v) => {
        return isObject(v);
      })
    ) {
      const tempObject = _fromEntries(value);
      const subRet = _processDataObject(tempObject, indent + 1);
      const subList = subRet.retList;
      const leftIndent = 4 * indent;
      retList.push(
        <ListItem key={key} sx={{ pl: leftIndent }}>
          <ListItemText primary={key + " (JSON)"} />
        </ListItem>
      );
      retList.push(
        <List component="nav" dense={true} key={key + "_sub"}>
          {subList}
        </List>
      );
    } else if (isJson) {
      const parsed = JSON.parse(value);
      const subRet = _processDataObject(parsed, indent + 1);
      const subList = subRet.retList;
      const leftIndent = 4 * indent;
      retList.push(
        <ListItem key={key} sx={{ pl: leftIndent }}>
          <ListItemText primary={key + " (JSON)"} />
        </ListItem>
      );
      retList.push(
        <List component="nav" dense={true} key={key + "_sub"}>
          {subList}
        </List>
      );
    } else if (value instanceof Object) {
      const subRet = _processDataObject(value, indent + 1);
      const subList = subRet.retList;
      const leftIndent = 4 * indent;
      retList.push(
        <ListItem key={key} sx={{ pl: leftIndent }}>
          <ListItemText primary={key + " (JSON)"} />
        </ListItem>
      );
      retList.push(
        <List component="nav" dense={true} key={key + "_sub"}>
          {subList}
        </List>
      );
    } else {
      const excludeArray = [
        "images",
        "bookable",
        "adac_prices",
        "marketing_data",
        "adac_attributes",
      ];
      if (key && !excludeArray.includes(key)) {
        const leftIndent = 4 * indent;
        retList.push(
          <ListItem key={key} sx={{ pl: leftIndent }}>
            {_createAttributeComponent(key, value)}
          </ListItem>
        );
      } else {
        console.log("key for else: " + key);
      }
    }
  }
  if (keys) {
    const len = keys.length;
    if (len === 0) {
      summaryText = "keine Eigenschaften vorhanden";
    } else if (len === 1) {
      summaryText = "eine Eigenschaft vorhanden";
    } else {
      summaryText = len + " Eigenschaften vorhanden";
    }
  }
  return { retList, summaryText };
}

function _fromEntries(value: any[]) {
  const ret = Object.entries(value);
  // const ret = Object.fromEntries(value);
  return ret;
}

function _createAttributeComponent(key: string, value: any) {
  // return <ObjectAttributeComponent attributeKey={key} dataObject={value} />
  return <SmartAttribute label={key} value={value} />;
}

export default GenericInfoComponent;
