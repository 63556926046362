import React from "react";
import SmartAccordion from "../../accordion/SmartAccordion";
import ScrapeLinkComponent from "./../../scrape_link/ScrapeLinkComponent";

interface IProps {
  scrapeLinks: any[];
}

function ScrapeLinksList({ scrapeLinks }: IProps): JSX.Element | null {
  let scrapeLinksSummaryText = "Links";
  if (scrapeLinks) {
    const len = scrapeLinks.length;
    if (len === 0) {
      scrapeLinksSummaryText = "keine Links vorhanden";
    } else if (len === 1) {
      scrapeLinksSummaryText = "ein Link vorhanden";
    } else {
      scrapeLinksSummaryText = len + " Links vorhanden";
    }
  }
  const ret = (
    <SmartAccordion header={scrapeLinksSummaryText}>
      {scrapeLinks.map((sl) => {
        return <ScrapeLinkComponent key={sl.Id} scrapeLinkDataObject={sl} />;
      })}
    </SmartAccordion>
  );
  return ret;
}

export default ScrapeLinksList;
