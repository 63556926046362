import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";

interface IProps {
  component: any;
}

function AuthenticationGuard({ component }: IProps): JSX.Element | null {
  const Component = withAuthenticationRequired(component,
    {
      onRedirecting: () => (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CircularProgress />
          </Box>
          <Typography align="center">Redirecting....</Typography>
        </Stack>
      ),
    }
  );
  return <Component />;
}

export default AuthenticationGuard;
