import { TuneTwoTone } from "@mui/icons-material";
import { AiTwotoneExperiment } from "react-icons/ai";
import { CgMenuGridR } from "react-icons/cg";
import { FaBeer, FaList, FaMap, FaMapPin } from "react-icons/fa";

export const APP_NAME = "Places and more";
export const DRAWER_WIDTH = 160;

export enum VisibleE {
  always = "ALWAYS",
  never = "NEVER",
  if_logged_in = "IF LOGGED IN",
  if_superuser = "IF SUPERUSER",
}
const TEST_VERSION: VisibleE = VisibleE.always;
export const SHOW_LOGIN = TEST_VERSION;
export const SHOW_FOOTER_ENTRIES = TEST_VERSION;

export type IMenuEntry = {
  name: string;
  tooltip: string;
  link: string;
  icon?: any;
  image?: string;
  mainMenu: VisibleE;
  kachel: VisibleE;
};
export const MENU_STRUCTURE: IMenuEntry[] = [
  {
    name: "Home",
    tooltip: "Hauptmenü",
    link: "menu",
    icon: CgMenuGridR,
    mainMenu: VisibleE.always,
    kachel: VisibleE.never,
  },
  {
    name: "Liste",
    tooltip: "Liste anzeigen",
    link: "list",
    icon: FaList,
    image: "/images/kachel/screenshot_list.png",
    mainMenu: VisibleE.always,
    kachel: VisibleE.always,
  },
  {
    name: "Ortssuche",
    tooltip: "Ort suchen",
    link: "searchlocation",
    icon: FaMapPin,
    image: "/images/kachel/screenshot_searchlocation.png",
    mainMenu: VisibleE.always,
    kachel: VisibleE.always,
  },
  {
    name: "Karte",
    tooltip: "Karte anzeigen",
    link: "map",
    icon: FaMap,
    image: "/images/kachel/screenshot_map.png",
    mainMenu: VisibleE.always,
    kachel: VisibleE.always,
  },
  {
    name: "Favoriten",
    tooltip: "Favoriten-Plätze anzeigen",
    link: "favorites",
    icon: FaList,
    image: "/images/kachel/screenshot_list.png",
    mainMenu: VisibleE.if_logged_in,
    kachel: VisibleE.if_logged_in,
  },
  {
    name: "Placelists",
    tooltip: "Placelists anzeigen",
    link: "placelists",
    icon: FaList,
    image: "/images/kachel/screenshot_list.png",
    mainMenu: VisibleE.if_logged_in,
    kachel: VisibleE.if_logged_in,
  },
  {
    name: "Lost Places",
    tooltip: "Lost Places",
    link: "lostPlaces",
    icon: AiTwotoneExperiment,
    mainMenu: VisibleE.if_superuser,
    kachel: VisibleE.if_superuser,
  },
  {
    name: "Einstellungen",
    tooltip: "Einstellungen vornehmen",
    link: "settings",
    icon: TuneTwoTone,
    image: "/images/kachel/screenshot_settings.png",
    mainMenu: VisibleE.always,
    kachel: VisibleE.always,
  },
  {
    name: "Statistiken",
    tooltip: "Statistiken anzeigen",
    link: "statistics",
    icon: FaBeer,
    mainMenu: VisibleE.if_superuser,
    kachel: VisibleE.if_superuser,
  },
  // {
  //   name: "Tests",
  //   tooltip: "Tests",
  //   link: "tests",
  //   icon: AiTwotoneExperiment,
  //   mainMenu: VisibleE.if_superuser,
  //   kachel: VisibleE.if_superuser,
  // },
  {
    name: "Test",
    tooltip: "Test",
    link: "test",
    icon: AiTwotoneExperiment,
    mainMenu: VisibleE.if_superuser,
    kachel: VisibleE.if_superuser,
  },
];
