import { Card, CardContent, CardHeader } from "@mui/material";
import React from "react";
import NavigateToProviderButton from "./../place/parts/NavigateToProviderButton";
import GenericInfoComponent from "../others/GenericInfoComponent";

interface IScrapeLinkComponentProps {
  scrapeLinkDataObject: any;
}
function ScrapeLinkComponent({
  scrapeLinkDataObject,
}: IScrapeLinkComponentProps): JSX.Element | null {
  let ret = <p>no data object found</p>;
  if (scrapeLinkDataObject) {
    const dataString = scrapeLinkDataObject.Data;
    const dataObject = JSON.parse(dataString);
    // const statusString = scrapeLinkDataObject.Status;
    // const statusObject = JSON.parse(statusString);
    const name = dataObject !== null ? dataObject.name : "<no name>";
    ret = (
      <Card>
        <CardHeader
          title={name}
          sx={{ pb: 0 }}
          subheader={"Id: " + scrapeLinkDataObject.Id}
        />
        <CardContent sx={{ pb: 0 }}>
          <NavigateToProviderButton scrapeLink={scrapeLinkDataObject} />
          <GenericInfoComponent
            key="generic_info"
            dataObject={scrapeLinkDataObject}
            hide={false}
          />
        </CardContent>
      </Card>
    );
  }
  return ret;
}

export default ScrapeLinkComponent;
