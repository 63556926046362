import React from "react";
import { IPlacelist, IPlacelistUser } from "../../AppModelTypes";
import SmartAccordion from "../accordion/SmartAccordion";
import SmartAttribute from "../others/SmartAttribute";
import { useUserService } from "../../services/UserService";
import { Typography } from "@mui/material";

interface IProps {
  placelist: IPlacelist;
}

function PlacelistUsersComponent({ placelist }: IProps): JSX.Element | null {
  let ret = null;
  const { userId } = useUserService();
  let myRole;
  let allUsersCount = 0;
  let editorUsersCount = 0;
  let viewerUsersCount = 0;
  if (placelist != null) {
    const plUsers: IPlacelistUser[] = placelist.Users;
    if (plUsers != null) {
      allUsersCount = plUsers.length;
      for (let i = 0; i < plUsers.length; i++) {
        const oneUser: IPlacelistUser = plUsers[i];
        const id = oneUser.UserId;
        const userRole = oneUser.Role;
        if (id === userId) {
          myRole = userRole;
        }
        if (userRole === "editor") {
          editorUsersCount++;
        }
        if (userRole === "viewer") {
          viewerUsersCount++;
        }
      }
    }
    const usersList = plUsers.map((u) => {
      return (
        <Typography key={u.UserId}>
          {u.UserId} ({u.Role})
        </Typography>
      );
    });

    ret = (
      <>
        <SmartAccordion header="Benutzer">{usersList}</SmartAccordion>
        <SmartAccordion header="Info">
          <SmartAttribute
            key="editors"
            label="Berabeiter"
            value={editorUsersCount}
          />
          <SmartAttribute
            key="viewers"
            label="Leser"
            value={viewerUsersCount}
          />
          <SmartAttribute key="all" label="Gesamt" value={allUsersCount} />
          <SmartAttribute key="myRole" label="meine Rolle" value={myRole} />
        </SmartAccordion>
      </>
    );
  }

  return ret;
}

export default PlacelistUsersComponent;
