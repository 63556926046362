import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { updateArray } from "../ArrayUtils";
import { isNullOrUndefined } from "../Utils";

export const placesApi = createApi({
  reducerPath: "placesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://placesdata.photobrandy.de/api.php/records/",
  }),
  tagTypes: ['Place', 'ScrapeLink', 'UserData'],
  endpoints: (builder) => ({
    getPlace: builder.query({
      query: (placeId) => "Places?filter=Id,eq," + placeId,
      providesTags: ['Places'],
    }),
    getPlaceJoined: builder.query({
      query: (placeId) => "Places?filter=Id,eq," + placeId + "&join=ScrapeLinks&join=PlaceAttachments",
      providesTags: ['Places'],
    }),
    getPlacesJoinFiltered: builder.query({
      query: (args) => {
        let ret = _getPlacesFilteredQuery(args);
        ret = ret + "&join=ScrapeLinks&join=PlaceAttachments";
        return ret;
      },
      providesTags: ['Places'],
    }),
    getPlacesFiltered: builder.query({
      query: (args) => _getPlacesFilteredQuery(args),
      providesTags: ['Places'],
    }),
    getPlacesFilteredAsync: builder.query({
      query: (args) => _getPlacesFilteredQuery(args),
      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: (a) => {
        const queryArgs = { ...a.queryArgs };
        queryArgs.page = null;
        queryArgs.maxResults = null;
        const ret = _getPlacesFilteredQuery(queryArgs);
        return ret;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, a) => {
        const currentRecords = currentCache.records
        const newRecords = newItems.records;
        // currentRecords.push(...newRecords);
        function _equalsPlaces(a, b) {
          const ret = (a.Id === b.Id);
          return ret;
        };
        updateArray(currentRecords, newRecords, _equalsPlaces, false);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
      providesTags: ['Places'],
    }),
    // getAllScrapeLinks: builder.query({
    //   query: () => "ScrapeLinks"
    // }),
    getScrapeLinks: builder.query({
      query: (placeId) => "ScrapeLinks?filter=PlaceId,eq," + placeId,
      providesTags: ['ScrapeLinks'],
    }),
    getScrapeLinksJoinFiltered: builder.query({
      query: (args) => _getScrapeLinksFilteredQuery(args),
      // query: (placeId) => "ScrapeLinks?filter=PlaceId,eq," + placeId,
      providesTags: ['ScrapeLinks'],
    }),
    getScrapeLink: builder.query({
      query: (scrapeLinkId) => "ScrapeLinks?filter=Id,eq," + scrapeLinkId,
      providesTags: ['ScrapeLinks'],
    }),
    getAllCountries: builder.query({
      query: () => "Places?include=Land",
      transformResponse: (response) => {
        const all = response.records;
        const countries = [];
        all.forEach((el) => {
          if (el.Land && !countries.includes(el.Land)) {
            countries.push(el.Land);
          }
        });
        countries.sort(function (w1, w2) {
          return w1.localeCompare(w2);
        });
        return countries;
      }
    }),
    getUserData: builder.query({
      query: (userId) => "UserData?filter=UserId,eq," + userId,
      providesTags: ['UserData'],
    }),
    createUserData: builder.mutation({
      query: data => ({
        url: `/UserData`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['UserData'],
    }),
    updateUserData: builder.mutation({
      query: data => ({
        url: `/UserData/${data.records[0].UserId}`,
        method: 'PUT',
        body: data.records[0]
      }),
      invalidatesTags: ['UserData'],
    }),
    getPlacelistsForUser: builder.query({
      query: (userId) => "Placelists?filter=Users,cs," + userId,
      providesTags: ['Placeslists'],
    }),
    getPlacelist: builder.query({
      query: (placelistId) => "Placelists?filter=Id,eq," + placelistId,
      providesTags: ['Placeslists'],
    }),
    createPlacelist: builder.mutation({
      query: (data) => ({
        url: `/Placelists`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Placelists'],
    }),
    updatePlacelistName: builder.mutation({
      query: ({ placelistId, newName }) => ({
        url: `/Placelists/` + placelistId,
        method: 'PUT',
        body: { 'Name': newName }
      }),
      invalidatesTags: ['Placeslists'],
    }),
    updatePlacelistPlaces: builder.mutation({
      query: ({ placelistId, places }) => ({
        url: `/Placelists/` + placelistId,
        method: 'PUT',
        body: { 'Places': places }
      }),
      invalidatesTags: ['Placeslists'],
    }),
    getAllPlaceAttachments: builder.query({
      query: () => "PlaceAttachments",
      providesTags: ['PlaceAttachments'],
    }),
    getPlaceAttachmentsForPlace: builder.query({
      query: (placeId) => "PlaceAttachments?filter=PlaceId,eq," + placeId,
      providesTags: ['PlaceAttachments'],
    }),
    createPlaceAttachment: builder.mutation({
      query: (data) => ({
        url: `/PlaceAttachments`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['PlaceAttachments'],
    }),
  })
});

function _getPlacesFilteredQuery(props) {
  // let ret = "/Places?join=ScrapeLinks";
  let ret = "/Places";
  ret = ret + "?order=updated_at,asc";
  if (props) {
    if (props.searchString && props.searchString.length > 0) {
      ret = ret + "&filter=Name,cs," + props.searchString;
    }
    if (
      props.maxPrice &&
      !(props.maxPrice === undefined) &&
      props.maxPrice >= 0
    ) {
      ret = ret + "&filter=Preis,le," + props.maxPrice;
    }
    if (props.maxResults) {
      ret = ret + "&size=" + props.maxResults;
    }
    if (
      props.onlyWithLocation &&
      !(props.onlyWithLocation === undefined) &&
      props.onlyWithLocation === true
    ) {
      // eslint-disable-next-line no-useless-concat
      ret = ret + "&filter=Latitude,nis" + "&filter=Longitude,nis";
    }
    if (props.bounds) {
      const bounds = props.bounds;
      ret =
        ret +
        "&filter=Latitude,bt," +
        bounds.south +
        "," +
        bounds.north +
        "&filter=Longitude,bt, " +
        bounds.west +
        ", " +
        bounds.east;
    }
    if (props.providers && props.providers.length > 0) {
      const providerList = props.providers;
      let providerListString = null;
      for (let i = 0; i < providerList.length; i++) {
        const p = providerList[i];
        const checked = p.checked;
        if (checked) {
          if (providerListString !== null) {
            providerListString = providerListString + "," + p.domain;
          } else {
            providerListString = p.domain;
          }
        }
      }
      if (providerListString && providerListString.length > 0) {
        ret = ret + "&filter=Domains,in," + providerListString;
      }
    }
    if (props.countries && props.countries.length > 0) {
      const countryList = props.countries;
      let countryListString = null;
      for (let i = 0; i < countryList.length; i++) {
        const c = countryList[i];
        if (countryListString === null) {
          countryListString = c;
        } else {
          countryListString = countryListString + "," + c;
        }
      }
      if (countryListString && countryListString.length > 0) {
        ret = ret + "&filter=Land,in," + countryListString;
      }
    }
    if (props.ids && props.ids.length > 0) {
      const idListString = _createListString(props.ids);
      if (idListString && idListString.length > 0) {
        ret = ret + "&filter=Id,in," + idListString;
      }
    }
    if (props.excludeIds && props.excludeIds.length > 0) {
      const excludeIdListString = _createListString(props.excludeIds);
      if (excludeIdListString && excludeIdListString.length > 0) {
        ret = ret + "&filter=Id,nin," + excludeIdListString;
      }
    }
    ret = ret + "&filter=Name,nis";
    ret = ret + "&filter=Land,nis";
    // ret = ret + "&filter=Name,neq,";
    const page = props.page;
    if (!isNullOrUndefined(page) && page > 0) {
      ret = ret + "&page=" + page;
      const pageSize = props.pageSize;
      if (pageSize && pageSize > 0) {
        ret = ret + "," + pageSize;
      }
    }
  } //if(props)
  return ret;
};
function _getScrapeLinksFilteredQuery(props) {
  // let ret = "/ScrapeLinks?join=Places";
  let ret = "/ScrapeLinks";
  ret = ret + "?order=updated_at,asc";
  if (props) {
    if (props.searchString && props.searchString.length > 0) {
      ret = ret + "&filter=Url,cs," + props.searchString;
    }
    if (props.maxResults) {
      ret = ret + "&size=" + props.maxResults;
    }
    if (
      props.onlyWithData &&
      !(props.onlyWithData === undefined) &&
      props.onlyWithData === true
    ) {
      // eslint-disable-next-line no-useless-concat
      ret = ret + "&filter=Data,nis";
    }
    if (
      props.onlyWithStatus &&
      !(props.onlyWithStatus === undefined) &&
      props.onlyWithStatus === true
    ) {
      // eslint-disable-next-line no-useless-concat
      ret = ret + "&filter=Status,nis";
    }
    if (props.ids && props.ids.length > 0) {
      const idListString = _createListString(props.ids);
      if (idListString && idListString.length > 0) {
        ret = ret + "&filter=Id,in," + idListString;
      }
    }
    if (props.excludeIds && props.excludeIds.length > 0) {
      const excludeIdListString = _createListString(props.excludeIds);
      if (excludeIdListString && excludeIdListString.length > 0) {
        ret = ret + "&filter=Id,nin," + excludeIdListString;
      }
    }
    const page = props.page;
    if (!isNullOrUndefined(page) && page > 0) {
      ret = ret + "&page=" + page;
      const pageSize = props.pageSize;
      if (pageSize && pageSize > 0) {
        ret = ret + "," + pageSize;
      }
    }
  } //if(props)
  return ret;
};

export const {
  useCreatePlaceAttachmentMutation,
  useGetPlaceAttachmentsForPlaceQuery,
  useGetAllPlaceAttachmentsQuery,
  useCreatePlacelistMutation,
  useUpdatePlacelistNameMutation,
  useUpdatePlacelistPlacesMutation,
  useGetPlacelistQuery,
  useGetPlacelistsForUserQuery,
  useGetUserDataQuery,
  useCreateUserDataMutation,
  useUpdateUserDataMutation,
  useGetAllCountriesQuery,
  useGetAllPlacesQuery, useGetPlaceQuery, useGetPlaceJoinedQuery,
  useGetPlacesFilteredQuery, useGetPlacesJoinFilteredQuery, useGetPlacesFilteredAsyncQuery,
  useGetScrapeLinkQuery, useGetScrapeLinksQuery, useGetScrapeLinksJoinFilteredQuery
} = placesApi;

function _createListString(idList) {
  let idListString = null;
  for (let i = 0; i < idList.length; i++) {
    const c = idList[i];
    if (idListString === null) {
      idListString = c;
    } else {
      idListString = idListString + "," + c;
    }
  }
  return idListString;
}
