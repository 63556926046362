import { ArticleTwoTone } from "@mui/icons-material";
import React from "react";
import { IPlace } from "../../AppModelTypes";
import ISmartAction from "../../components/buttons/ISmartAction";

function createNavigateToPlaceDetailsAction(
  place: IPlace,
  navigate: any,
  currentPathname?: string
): ISmartAction {
  const pid = place ? place.Id : null;
  const text = "Details zu Platz " + pid + " anzeigen";

  function handleMouse() {
    if (navigate != null) {
      navigate("/place/" + pid);
    }
  }
  return {
    text,
    onMouse: handleMouse,
    children: <ArticleTwoTone />,
    disabled: pid == null || navigate == null,
    invisible:
      currentPathname != null && currentPathname.includes("/place/" + pid),
  };
}

export default createNavigateToPlaceDetailsAction;
