export function isDebugInfo() {
  const storedInfoFlag = localStorage.getItem("info");
  const ret = storedInfoFlag == null ? false : JSON.parse(storedInfoFlag);
  return ret;
}

export function isSamePlace(place_1: any, place_2: any) {
  if (place_1 == null || place_1.Id == null) {
    return false;
  }
  if (place_2 == null || place_2.Id == null) {
    return false;
  }
  return place_1.Id === place_2.Id;
}

export function getLocationOfPlace(place: any) {
  let ret = null;
  if (place != null && place.Latitude != null && place.Longitude != null) {
    ret = {
      Latitude: place.Latitude,
      Longitude: place.Longitude,
    };
  }
  return ret;
}
