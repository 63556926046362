import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@mui/material";
import React, { useState } from "react";

interface IParams {
  open: boolean;
  oldValue: string;
  onOk?: (newValue: string) => void;
  onClose?: () => void;
  title?: string;
  label?: string;
}


function RenameDialog(props: IParams) {
  const [value, setValue] = useState(props.oldValue);
  const title = props.title ? props.title : "Umbenennen";
  const label = props.label ? props.label : "Name";
  function handleClose() {
    if (props.onClose) {
      props.onClose();
    }
  }
  function handleOk() {
    if (props.onOk) {
      props.onOk(value);
    }
  }

  return (
    <div>
      <Dialog onClose={props.onClose} open={props.open} scroll="paper" fullScreen>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
          {/* <SmartAttribute label="Name" value={p.oldValue} /> */}
          <TextField key="text"
            fullWidth
            label={label}
            defaultValue={props.oldValue}
            InputProps={{
              readOnly: false,
            }}
            margin="dense"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setValue(event.target.value);
            }}
          // size="small"
          // helperText={helperText}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button
            disabled={(value == null) || (value.length < 1) || (value === props.oldValue)}
            onClick={handleOk}
          >
            Speichern
          </Button>
        </DialogActions>{" "}
      </Dialog>
    </div>
  );
}

export default RenameDialog;
