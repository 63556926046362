import React from "react";
import GridContainer from "../containers/GridContainer";
import PlaceSmallFromId from "./../place/PlaceSmallFromId";

type IProps = {
  placeIds: string[];
  fromLatiude?: number;
  fromLongitude?: number;
};

function PlacesListFromIds(props: IProps) {
  function createPlaceComponent(pid: any) {
    return (
      <PlaceSmallFromId
        key={"Place__" + pid}
        placeId={pid.toString()}
        enableFlyTo={true}
        fromLatiude={props.fromLatiude}
        fromLongitude={props.fromLongitude}
      />
    );
  }

  return (
    <GridContainer items={props.placeIds} renderItem={createPlaceComponent} />
  );
}

export default PlacesListFromIds;
