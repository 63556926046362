import { DriveFileRenameOutlineTwoTone } from "@mui/icons-material";
import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import ISmartAction from "../../components/buttons/ISmartAction";
import { IPlacelist } from "../../AppModelTypes";
import { IForm } from "../../components/forms/IForm";

function RenamePlacelistDialogContent(props: IForm): React.JSX.Element {
  const oldName: string = props.data.oldName;
  const [nameText, setNameText] = useState<string>(oldName);
  useEffect(() => {
    if (props.onDataChange != null) {
      const data = { name: nameText };
      props.onDataChange(data);
    }
    if (props.onError != null) {
      if (nameText == null || nameText.length < 1) {
        props.onError("Bitte einen Namen für die Placelist eingeben");
      } else if (nameText === oldName) {
        props.onError("Bitte einen neuen Namen für die Placelist eingeben");
      } else {
        props.onError(null);
      }
    }
  }, [nameText, oldName, props]);

  const content = (
    <TextField
      label="Name"
      defaultValue={nameText}
      margin="dense"
      fullWidth
      onChange={(e) => {
        setNameText(e.target.value);
      }}
    />
  );
  return content;
}

function createRenamePlacelistAction(
  placelist: IPlacelist,
  renamePlacelist: (newName: string) => void
): ISmartAction {
  function handleDialogOk(data: any) {
    const name: string = data.name;
    renamePlacelist(name);
  }
  function _contentCreator(props: IForm) {
    const oldName = placelist.Name;
    const content = (
      <RenamePlacelistDialogContent
        data={{ oldName: oldName }}
        onDataChange={props.onDataChange}
        onError={props.onError}
      />
    );
    return { content };
  }
  const ret: ISmartAction = {
    text: "Placelist umbenennen",
    shortText: "Umbenennen...",
    children: <DriveFileRenameOutlineTwoTone />,
    dialogProps: {
      title: "Placelist umbenennen",
      onCreateContent: _contentCreator,
      onOk: handleDialogOk,
    },
  };
  return ret;
}

export default createRenamePlacelistAction;
