import React from "react";
import { useParams } from "react-router-dom";
import { StyledContent } from './../components/styles/styled.Content';
import PlaceFromId from "../components/place/PlaceFromId";

export function PlacePage() {
  const routerParams = useParams();

  let p = null;
  if (routerParams && routerParams.id) {
    const placeIdString: string = routerParams.id;
    p = <PlaceFromId placeId={placeIdString} showTitle={false} />
  }

  return (
    <StyledContent>
      {p}
    </StyledContent>
  );
}
