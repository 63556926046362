import React from "react";
import LeafletMapComponent from "../components/maps/LeafletMapComponent";
import { randomItemFromArray } from "../ArrayUtils";

const FLY_TO = false;
const placeIds = [100, 1427546, 1327451, 1332586, 1427545, 12130, 300];

export function TestPage() {
  if (FLY_TO) {
    const rnd = randomItemFromArray(placeIds);
    sessionStorage.setItem("flyToPlaceId", rnd);
  }
  return (
    <LeafletMapComponent storageKeyCurrentBounds="TestPage_currentBounds" />
  );
}
