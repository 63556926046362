import { Typography } from "@mui/material";
import React from "react";
import { IPlaceAttachment } from "../../AppModelTypes";
import { parseJsonIfNeeded } from "../../Utils";
import { useUserService } from "../../services/UserService";
import GenericInfoComponent from "../others/GenericInfoComponent";
import SmartAttribute from "../others/SmartAttribute";

interface IProps {
  placeAttachment: IPlaceAttachment;
}

function PlaceAttachmentComponent({
  placeAttachment,
}: IProps): JSX.Element | null {
  const { isSuperuser } = useUserService();

  let mainComp = (
    <Typography>unbekannter Anhang #{placeAttachment.Id}</Typography>
  );
  const data = parseJsonIfNeeded(placeAttachment.Data);
  if (data) {
    if (data.url != null) {
      const url = data.url;
      mainComp = (
        <>
          {/* <SmartLink href={data.url}>Link</SmartLink> */}
          <SmartAttribute label="Anhang" value={url} showPreview={true} />
        </>
      );
    }
  }

  return (
    <>
      {mainComp}
      {isSuperuser ?? <GenericInfoComponent dataObject={placeAttachment} />}
    </>
  );
}

export default PlaceAttachmentComponent;
