import { VisibilityTwoTone } from "@mui/icons-material";
import {
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import SmartDialogButton from "../dialogs/SmartDialogButton";
import SmartEmbedLazy from "../embed/SmartEmbedLazy";
import { isValidUrl, loadImageFromUrlIfPossible } from "./../../Utils";
import SmartLabeledField from "./SmartLabeledField";
import { IDialogProps } from "../dialogs/IDialogProps";

interface IProps {
  label: string;
  value: any;
  showPreview?: boolean;
}

function SmartAttribute({
  label,
  value,
  showPreview,
}: IProps): JSX.Element | null {
  if (showPreview == null) {
    showPreview = false;
  }
  const [image, setImage] = useState<null | HTMLImageElement>(null);
  function imageCallback(imageData: HTMLImageElement | null): void {
    if (imageData != null) {
      setImage(imageData);
    }
  }

  if (image != null) {
    return (
      <TextField
        key={label}
        fullWidth
        id="outlined-read-only-input"
        label={label}
        variant="outlined"
        // defaultValue={value}
        value={value}
        sx={{
          ".MuiOutlinedInput-root": {
            paddingTop: "1rem",
            flexDirection: "column",
            alignItems: "start",
          },
          img: {
            paddingRight: "1rem",
          },
        }}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <img
              src={value}
              alt=""
              // loading="lazy"
            />
          ),
        }}
        margin="dense"
        // size="small"
        // helperText={value}
      />
    );
  }

  let helperText = null;
  let ret = null;
  if (value === undefined) {
    value = "-";
    helperText = "undefined";
  } else if (value === null) {
    value = "-";
    helperText = "null";
  }
  if (isValidUrl(value)) {
    try {
      loadImageFromUrlIfPossible(value, imageCallback);
    } catch (err) {
      console.log(err);
    }
    ret = createNewField(label, value, showPreview);
    // ret = createOldField(label, value, dialogProps, showPreview);
  } else {
    ret = (
      <TextField
        key={label}
        fullWidth
        // id="outlined-read-only-input"
        label={label}
        defaultValue={value}
        InputProps={{
          readOnly: true,
        }}
        margin="dense"
        // size="small"
        helperText={helperText}
      />
    );
  }
  return ret;
}

export default SmartAttribute;

// ---------------------------------------------
// Helpers
function createNewField(label: string, value: any, showPreview: boolean): any {
  const preview =
    showPreview === true ? (
      <SmartEmbedLazy header="Vorschau" url={value} />
    ) : (
      <Typography sx={{ opacity: 0.5 }}>{value}</Typography>
    );
  return (
    <SmartLabeledField label={label}>
      <Stack
        flexGrow={1}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        columnGap={2}
        sx={{ alignItems: "center" }}
        useFlexGap
      >
        <Link noWrap={true} href={value} target="_blank">
          Link
        </Link>
        {preview}
      </Stack>
    </SmartLabeledField>
  );
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createOldField(
  label: string,
  value: any,
  dialogProps: IDialogProps,
  showPreview: boolean
): any {
  return (
    <>
      <TextField
        key={label + "_image"}
        fullWidth
        // id="outlined-read-only-input"
        label={label}
        // defaultValue={value}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="end">
              <Link noWrap={true} href={value} target="_blank">
                Link
              </Link>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {/* <IconButton aria-label="toggle visibility" onClick={runPreview}>
                          <VisibilityTwoTone />
                        </IconButton> */}
              <SmartDialogButton
                dialogProps={dialogProps}
                text={"Vorschau"}
                shortText={"Vorschau"}
                showLabel={false}
              >
                <VisibilityTwoTone />
              </SmartDialogButton>
            </InputAdornment>
          ),
        }}
        margin="dense"
        // size="small"
        helperText={value}
      />
      {showPreview && <SmartEmbedLazy url={value} />}
    </>
  );
}

// function runPreview(event: any): void {
//   throw new Error("Function not implemented.");
// }
