import React from "react";
import { IPlace } from "../../AppModelTypes";
import GridContainer from "../containers/GridContainer";
import PlaceSmallFromId from "../place/PlaceSmallFromId";
import PlaceSmall from "./../place/PlaceSmall";

type IProps = {
  places: IPlace[];
  fromLatiude?: number;
  fromLongitude?: number;
};

function PlacesList({ places, fromLatiude, fromLongitude }: IProps) {
  function createPlaceComponent(p: IPlace) {
    if (p.Name != null) {
      return (
        <PlaceSmall
          key={"Place__" + p.Id}
          placeObject={p}
          enableFlyTo={true}
          fromLatiude={fromLatiude}
          fromLongitude={fromLongitude}
        />
      );
    } else {
      return (
        <PlaceSmallFromId
          key={"Place__" + p.Id}
          placeId={p.Id}
          enableFlyTo={true}
          fromLatiude={fromLatiude}
          fromLongitude={fromLongitude}
        />
      );
    }
  }

  return <GridContainer items={places} renderItem={createPlaceComponent} />;
}

export default PlacesList;
