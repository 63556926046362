import { Card, CardActionArea, CardMedia, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { isNullOrUndefined } from "../Utils";
import { generateSXForTextBorder } from "./MuiUtils";
import SmartTooltip from "./others/SmartTooltip";

interface IProps {
  text: string;
  tooltip?: string;
  icon?: any;
  image?: string;
  href: string;
}

function Kachel({
  text,
  tooltip,
  icon,
  image,
  href,
}: IProps): JSX.Element | null {
  if (!tooltip) {
    tooltip = text;
  }
  const navigate = useNavigate();
  function _click(event: any): void {
    navigate(href);
  }

  let cardMedia = null;
  if (isNullOrUndefined(image)) {
    image = "https://picsum.photos/400/300";
  }
  cardMedia = (
    <CardMedia
      component="img"
      // image="https://picsum.photos/400/300"
      image={image}
      // alt="CardMedia Image Example"
      height="140"
      // title="CardMedia Image Example"
      style={{ filter: "blur(1px)" }}
    />
  );

  let sxForTypo =
    generateSXForTextBorder("2px", "0", "rgba(0, 0, 0, .5)") + ", ";
  sxForTypo = sxForTypo + "5px 7px 7px rgba(0, 0, 0, 1)";
  return (
    <SmartTooltip title={tooltip}>
      <Card
        style={{
          position: "relative",
          width: "auto",
          margin: 10,
          flexGrow: 1,
        }}
        raised={true}
        elevation={5}
        sx={{ m: 1.5, borderRadius: 2 }}
      >
        <CardActionArea onClick={_click}>
          {cardMedia}
          <Typography
            sx={{ fontWeight: "bold", textShadow: sxForTypo }}
            style={{
              position: "absolute",
              top: "30%",
              width: "100%",
              padding: "100, 100,100,100",
              textAlign: "center",
              color: "white",
              backgroundColor: "none",
              // wordWrap: "break-word",
            }}
            noWrap
            align="center"
            variant="h3"
            gutterBottom
          >
            {text}
          </Typography>
        </CardActionArea>
      </Card>
    </SmartTooltip>
  );
}

export default Kachel;
