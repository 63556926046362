import { LocationOnTwoTone } from "@mui/icons-material";
import React from "react";
import ISmartAction from "../../components/buttons/ISmartAction";
import { IScrapeLink } from "../../AppModelTypes";
import { makeBetterUrl, navigateToUrl, stripProviderName } from "../../Utils";

function createNavigateToProviderAction(scrapeLink:IScrapeLink): ISmartAction {
  // return <ScrapeLink key={sl.Id} scrapeLink={sl} />;
  const url = scrapeLink.Url;
  const p = stripProviderName(url);
  const betterUrl = makeBetterUrl(url);
  const tooltipText = "Direkt zu " + p;

  function handleMouse() {
    const url = betterUrl;
    if (url != null) {
      navigateToUrl(url);
    }
  }

  return {
    text: tooltipText,
    onMouse: handleMouse,
    children: <LocationOnTwoTone />,
    disabled: scrapeLink == null,
  };
}

export default createNavigateToProviderAction;
