import {
  PendingOutlined,
  SentimentVeryDissatisfied, SentimentVerySatisfied
} from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useNavigate } from 'react-router-dom';
import SmartTooltip from "../others/SmartTooltip";
import { useUserService } from '../../services/UserService';

function AuthenticationComponent() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { loginWithRedirect, user, logout, isAuthenticated, isLoading } = useUserService();
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let tooltipText = "Anmelden";
  if (isLoading) {
    tooltipText = "lädt..."
  } else {
    if (isAuthenticated) {
      if (user != null && user.nickname != null) {
        tooltipText = "Angemeldet als " + user.nickname;
      } else {
        tooltipText = "Angemeldet";
      }
    }
  }

  let iconForButton = null;
  if (isLoading) {
    iconForButton = <PendingOutlined fontSize="inherit" />;
  } else {
    if (isAuthenticated) {
      iconForButton = <SentimentVerySatisfied fontSize="inherit" />
    } else {
      iconForButton = <SentimentVeryDissatisfied fontSize="inherit" />
    }
  }

  //loginWithRedirect({ redirectUri: ${window.location.origin}/home, appState: {returnTo: href } });
  const appState = {
    screen_hint: 'signup',
    appState: {
      returnTo: window.location.pathname //href
    }
  };

  let ret = <>
    <SmartTooltip title={tooltipText} >
      <IconButton
        color="inherit"
        aria-label="menu"
        size="large"
        onClick={handleClick}
      >
        {iconForButton}
      </IconButton>
    </SmartTooltip>
    {isLoading ? null : <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {isAuthenticated ? null : <MenuItem onClick={() => { loginWithRedirect( appState) }}>Anmelden</MenuItem>}
      {isAuthenticated ? <MenuItem onClick={() => { navigate('myaccount'); handleClose(); }}>Mein Account</MenuItem> : null}
      {isAuthenticated ? <MenuItem onClick={() => { navigate('myplaces'); handleClose(); }}>Meine Plätze</MenuItem> : null}
      {isAuthenticated ? <MenuItem onClick={() => { logout() }}>Abmelden</MenuItem> : null}
    </Menu>}
  </>;
  return ret
}

export default AuthenticationComponent;

