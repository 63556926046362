import { Stack, Typography } from "@mui/material";
import React from "react";
import { useUserService } from "../services/UserService";
import GenericInfoComponent from '../components/others/GenericInfoComponent';
import { isDebugInfo } from './../AppUtils';
import PlacesListFromIds from './../components/places/PlacesListFromIds';
import { StyledContent } from './../components/styles/styled.Content';

function MyPlacesPage() {
  const { favoritePlaceIds, userDataObject, isServiceReady } = useUserService();
  if (!isServiceReady) {
    return <Typography>lädt...</Typography>;
  }
  let theList = <Stack
    direction="column"
    justifyContent="center"
    alignItems="center"
    spacing={2}
  >
    <Typography>Keine Favoriten gespeichert</Typography>
  </Stack>;
  if (favoritePlaceIds != null && favoritePlaceIds.length > 0) {
    theList = <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Typography>{favoritePlaceIds.length} Favoriten gespeichert</Typography>
      <PlacesListFromIds placeIds={favoritePlaceIds} />
    </Stack>;
  }
  return (
    <StyledContent>
      {theList}
      {isDebugInfo() && <GenericInfoComponent dataObject={userDataObject} open={true} />}
    </StyledContent>
  );
}

export default MyPlacesPage;