import Axios from "axios";
import { TravelTimeClient } from "traveltime-api";

export const MAX_STATISTICS_POINTS = 5000;
export const api = Axios.create({
  baseURL: "https://placesdata.photobrandy.de/api.php/records",
});

export const getPlaces = async (props) => {
  let text = "/Places?join=ScrapeLinks";
  if (props) {
    if (props.searchString && props.searchString.length > 0) {
      text = text + "&filter=Name,cs," + props.searchString;
    }
    if (
      props.country &&
      !(props.country === undefined) &&
      !(props.country === "undefined")
    ) {
      text = text + "&filter=Land,eq," + props.country;
    }
    if (
      props.maxPrice &&
      !(props.maxPrice === undefined) &&
      props.maxPrice >= 0
    ) {
      text = text + "&filter=Preis,le," + props.maxPrice;
    }
    if (props.maxSize) {
      text = text + "&size=" + props.maxSize;
    }
    if (
      props.onlyWithLocation &&
      !(props.onlyWithLocation === undefined) &&
      props.onlyWithLocation === true
    ) {
      // eslint-disable-next-line no-useless-concat
      text = text + "&filter=Latitude,nis" + "&filter=Longitude,nis";
    }
    if (props.bounds) {
      const bounds = props.bounds;
      text =
        text +
        "&filter=Latitude,bt," +
        bounds.south +
        "," +
        bounds.north +
        "&filter=Longitude,bt, " +
        bounds.west +
        ", " +
        bounds.east;
    }
    if (props.providers && props.providers.length > 0) {
      const providerList = props.providers;
      let providerListString = null;
      for (let i = 0; i < providerList.length; i++) {
        const p = providerList[i];
        const checked = p.checked;
        if (checked) {
          if (providerListString !== null) {
            providerListString = providerListString + "," + p.domain;
          } else {
            providerListString = p.domain;
          }
        }
      }
      text = text + "&filter=Domains,in," + providerListString;
    }
    text = text + "&filter=Name,nis";
    text = text + "&filter=Name,neq,";
    text = text + "&order=updated_at,asc";
    const page = props.page;
    if (page && page > 0) {
      text = text + "&page=" + page;
      const pageSize = props.pageSize;
      if (pageSize && pageSize > 0) {
        text = text + "," + pageSize;
      }
    }
  } //if(props)
  const response = await api.get(text);
  return response.data.records;
};

export const getStatistics = async (props) => {
  let statisticsCache = JSON.parse(sessionStorage.getItem("statisticsCache"));
  if (statisticsCache) {
    const cacheDate = statisticsCache.cacheDate;
    const cache = statisticsCache.cache;
    const diff = new Date() - new Date(cacheDate);
    if (cache && cacheDate && diff < 1000) {
      return cache;
    }
  }
  let text = "/Statistics?order=Date,asc";
  const response = await api.get(text);
  let records = response.data.records;
  const recordsLength = records.length;
  if (recordsLength > MAX_STATISTICS_POINTS) {
    records = records.slice(recordsLength - MAX_STATISTICS_POINTS);
  }
  records = addElementForNow(records);
  const ret = addHeartbeat(records);
  statisticsCache = { cache: ret, cacheDate: new Date() };
  sessionStorage.setItem("statisticsCache", JSON.stringify(statisticsCache));
  return ret;
};

function addElementForNow(records) {
  const lastElement = records.pop();
  const newElement = {
    Date: new Date().toISOString().slice(0, 19).replace("T", " "),
    Data: lastElement.Data,
  };
  records.push(lastElement);
  records.push(newElement);
  return records;
}

function addHeartbeat(res) {
  const parsed = res; // JSON.parse(res);
  for (let index = 0; index < parsed.length; index++) {
    const element = parsed[index];
    if (index < 1) {
      const data = JSON.parse(element.Data);
      data.placesDiff = 0;
      data.scrapeLinksDiff = 0;
      element.DataPlus = JSON.stringify(data);
    } else {
      const date = new Date(element.Date);
      const preDate = new Date(res[index - 1].Date);
      const dateDiff = (date - preDate) / 1000 / 60; // in minutes
      const data = JSON.parse(element.Data);
      const preData = JSON.parse(res[index - 1].Data);
      data.placesDiff = data.places - preData.places;
      data.placesDiffRel = (data.places - preData.places) / dateDiff;
      data.scrapeLinksDiff = data.scrapeLinks - preData.scrapeLinks;
      data.scrapeLinksDiffRel =
        (data.scrapeLinks - preData.scrapeLinks) / dateDiff;
      element.DataPlus = JSON.stringify(data);
    }
  }
  return res; // JSON.stringify(parsed);
}

export async function geoencodeText(text) {
  // console.log("geoencodeText(" + text + ")");
  let ret;
  const params = {
    access_key: "69595e2311d3b141f00b087eff27f99e",
    query: text,
    limit: 1,
  };

  try {
    const response = await Axios.get(
      "https://geocode.xyz/" + text + "?json=1&auth=22362855686766956435x59916",
      { params }
    );
    ret = response.data;
  } catch (err) {
    console.log("geoencodeText(" + text + ") -> " + err.message);
  }
  return ret;
}

export async function geoencodeText_Traveltime(text) {
  // console.log("geoencodeText(" + text + ")");
  let ret;

  const travelTimeClient = new TravelTimeClient({
    apiKey: "db0eabb8aae33828e4e06a16ec870666",
    applicationId: "05a8fa9a",
  });
  ret = travelTimeClient
    .geocoding(text)
    .then((data) => {
      // console.log(data);
      return data;
    })
    .catch((e) => console.error(e));
  return ret;
}
