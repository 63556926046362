import React from "react";
import styled from "styled-components";
import { VisibleE } from "../AppConstants";
import Kachel from './Kachel';
import { useUserService } from "../services/UserService";
import { StyledContent } from "./styles/styled.Content";

const StyledKachelmenu = styled(StyledContent)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  flex-grow: 1;
  flex-shrink: 0;
  gap: 10px;
  margin: 10px;
  @media only screen and (min-width: 432px) {
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
    align-items: stretch;
    align-content: flex-start;
    flex-grow: 1;
    flex-shrink: 0;
  }
`;

function _createKachel(x: {
  [x: string]: string | undefined; kachel: VisibleE; link: string; icon: any; name: string; tooltip: string | undefined;
}) {
  const link = "/" + x.link;
  const ri = x.icon;
  const ret = (
    <Kachel
      key={x.name}
      text={x.name}
      icon={ri}
      image={x.image}
      tooltip={x.tooltip}
      href={link}
    />
  );
  return ret;
}

interface IProps {
  menuStructure: any
}

function Kachelmenu({ menuStructure }: IProps) {
  const { isAuthenticated, isSuperuser } = useUserService();
  const filteredList = menuStructure.filter((x: any) => {
    return (
      x.kachel === VisibleE.always ||
      (isAuthenticated && x.kachel === VisibleE.if_logged_in) ||
      (isSuperuser && x.kachel === VisibleE.if_superuser)
    );
  });
  const list = filteredList.map((x: any) => _createKachel(x));
  return <StyledKachelmenu>{list}</StyledKachelmenu>;
}

export default Kachelmenu;