import { Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query/index.js";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { isNullOrUndefined } from "../../Utils";
import {
  useGetAllPlaceAttachmentsQuery,
  useGetPlacesFilteredQuery,
} from "../../api/PlacesApiSlice.js";
import { concatArrayWithoutDuplicates } from "./../../ArrayUtils";
import PlacesList from "./PlacesList";
import { useUserService } from "../../services/UserService";

interface IProps {
  queryArguments: any;
  pageSize?: number;
  fromLatiude?: number;
  fromLongitude?: number;
  updateCount?: (count: null | number) => void;
}

function PlacesResultList({
  queryArguments,
  pageSize,
  fromLatiude,
  fromLongitude,
  updateCount,
}: IProps): JSX.Element | null {
  if (isNullOrUndefined(pageSize)) {
    pageSize = 10;
  }
  const [storedQueryArguments, setStoredQueryArguments] = useState<null | any>(
    undefined
  );
  const [storedPageSize, setStoredPageSize] = useState<undefined | number>(
    undefined
  );
  const [queryArgs, setQueryArgs] = useState<null | any>(undefined);
  const [allResultsCount, setAllResultsCount] = useState<null | number>(null);
  const [places, setPlaces] = useState<any[]>([]);
  const { placeAttachmentsData } = useGetAllPlaceAttachmentsQuery();
  const {
    data: placesData,
    /*isLoading,*/ isFetching,
    isSuccess,
    isError,
    error,
  } = useGetPlacesFilteredQuery(queryArgs ?? skipToken);
  const { isSuperuser } = useUserService();

  useEffect(() => {
    if (queryArgs != null && placeAttachmentsData != null) {
      const specialHandling =
        isSuperuser && queryArgs.showPlacesWithoutAttachment === false;
      if (specialHandling) {
        const placeIdList = [];
        const placeAttachments = placeAttachmentsData.records;
        for (let index = 0; index < placeAttachments.length; index++) {
          const ap = placeAttachments[index];
          placeIdList.push(ap.PlaceId);
        }
        if (queryArgs.ids == null) {
          const qaTemp = { ...queryArgs };
          qaTemp.ids = placeIdList;
          setQueryArgs(qaTemp);
        }
      }
    }
  }, [isSuperuser, placeAttachmentsData, queryArgs]);

  useEffect(() => {
    // console.log("useEffect(): [pageSize, queryArguments, storedPageSize, storedQueryArguments]");
    if (isNullOrUndefined(queryArguments) || isNullOrUndefined(pageSize)) {
      return;
    }
    let initNeeded = false;
    if (storedQueryArguments !== queryArguments) {
      setStoredQueryArguments(queryArguments);
      initNeeded = true;
    }
    if (storedPageSize !== pageSize) {
      setStoredPageSize(pageSize);
      initNeeded = true;
    }
    if (initNeeded) {
      // console.log("init needed");
      setPlaces([]);
      setAllResultsCount(null);
      if (updateCount != null) {
        updateCount(null);
      }
      const initialQueryArgs = { ...queryArguments };
      initialQueryArgs.page = 1;
      initialQueryArgs.pageSize = pageSize;
      setQueryArgs(initialQueryArgs);
    }
  }, [
    pageSize,
    queryArguments,
    storedPageSize,
    storedQueryArguments,
    updateCount,
  ]);

  useEffect(() => {
    // console.log("useEffect(): [data, places] ");
    // console.log("useEffect(): [data, places] data: " + JSON.stringify(data) + "   places: " + JSON.stringify(places));
    if (placesData) {
      const morePlaces = placesData.records;
      if (morePlaces) {
        // console.log("  morePlaces: " + morePlaces[0].Id);
        if (places && places.length > 0) {
          const oldLength = places.length;
          const concat = concatArrayWithoutDuplicates(places, morePlaces);
          const newLength = concat.length;
          if (oldLength !== newLength) {
            setPlaces(concat);
          }
        } else {
          setPlaces(morePlaces);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placesData]);

  // update allResultsCount and call the callback
  useEffect(() => {
    // console.log("useEffect(): [data]");
    if (placesData) {
      const oldCount = allResultsCount;
      const newCount = placesData.results;
      if (oldCount !== newCount) {
        // console.log("  setAllResultsCount(newCount);");
        setAllResultsCount(newCount);
        if (updateCount != null) {
          updateCount(newCount);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placesData, updateCount]);

  function _hasMore(): boolean {
    if (allResultsCount == null) {
      return true;
    }
    let ret = false;
    if (!places || places.length < allResultsCount) {
      if (queryArgs && pageSize) {
        const page = queryArgs.page;
        if (page > 0) {
          if (page * pageSize < allResultsCount) {
            ret = true;
          }
        }
      } else {
        ret = true;
      }
    }
    // const placesLength = !places ? "undefined" : places.length;
    // console.log("_hasMore() answers " + ret + "(" + placesLength + " / " + allResultsCount + ")");
    return ret;
  }

  function _doNext() {
    // console.log("_doNext(): " + JSON.stringify({ isLoading, isFetching, isSuccess, isError, error }));
    if (isFetching) {
      // console.log("_doNext(): do nothing because isFetching: " + isFetching);
      return;
    }
    const newQueryArgs = { ...queryArgs };
    const nextPage = newQueryArgs.page + 1;
    newQueryArgs.page = nextPage;
    setQueryArgs(newQueryArgs);
    // console.log("_doNext(): set next page to " + nextPage);
  }

  let content = null;
  if (isSuccess) {
    let placeList = null;
    if (!!places) {
      placeList = (
        <PlacesList
          places={places}
          fromLatiude={fromLatiude}
          fromLongitude={fromLongitude}
        />
      );
    }
    content = (
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        {/* <Typography>{allResultsCount} Plätze gefunden</Typography> */}
        <InfiniteScroll
          dataLength={places ? places.length : 0}
          hasMore={_hasMore()}
          next={_doNext}
          loader={<h4>Lädt nach...</h4>}
          // endMessage={<Typography>Ende der Liste erreicht</Typography>}
        >
          {placeList}
        </InfiniteScroll>
      </Stack>
    );
  } else if (isError) {
    console.log(" Error");
    content = <div>{error.toString()}</div>;
  }
  return content;
}

export default PlacesResultList;
