import { IPlacelist } from "../../AppModelTypes";
import { useGetPlacelistQuery } from "../../api/PlacesApiSlice";
import useAppService from "../../services/AppService";
import { usePlacelistsService } from "../../services/PlacelistsService";
import createCreatePlacelistAction from "./CreatePlacelistAction";
import createNavigateToPlacelistDetailsAction from "./NavigateToPlacelistDetailsAction";
import createRenamePlacelistAction from "./RenamePlacelistAction";
import { concatAllArraysWithoutDuplicates } from "../../ArrayUtils";
import AddUserToPlacelistAction from "./AddUserToPlacelistAction";

function usePlacelistActions(placelistId?: string, placelist?: IPlacelist) {
  const { createPlacelist, updatePlacelistName } = usePlacelistsService();
  const { navigate, pathname } = useAppService();
  const { data: queryResult } = useGetPlacelistQuery(placelistId);
  let placelistObject = placelist;
  if (placelistObject == null) {
    if (queryResult) {
      if (queryResult.records && queryResult.records[0]) {
        placelistObject = queryResult.records[0];
      }
    }
  }
  if (placelistId == null) {
    placelistId = placelistObject?.Id;
  }
  function renamePlacelist(newName: string) {
    if (placelistId != null) {
      updatePlacelistName(placelistId, newName);
    }
  }
  const onePlacelistActions = [];
  const overPlacelistActions = [];
  if (placelistObject != null) {
    onePlacelistActions.push(
      createNavigateToPlacelistDetailsAction(
        placelistObject,
        navigate,
        pathname
      )
    );
    const renameAction = createRenamePlacelistAction(
      placelistObject,
      renamePlacelist
    );
    onePlacelistActions.push(renameAction);
    const addUserAction = AddUserToPlacelistAction(placelistObject);
    onePlacelistActions.push(addUserAction);
  }
  const createAction = createCreatePlacelistAction(createPlacelist);
  overPlacelistActions.push(createAction);

  const allPlacelistActions = concatAllArraysWithoutDuplicates([
    onePlacelistActions,
    overPlacelistActions,
  ]);

  const ret = {
    placelistObject,
    onePlacelistActions,
    overPlacelistActions,
    allPlacelistActions,
  };
  return ret;
}

export default usePlacelistActions;
