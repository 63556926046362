import {
  CardActions,
  CardActionsProps
} from "@mui/material";
import React from "react";

function SmartCardActions(props: CardActionsProps) {
  return <CardActions {...props} sx={{ display: "block" }} />;
}

export default SmartCardActions;
