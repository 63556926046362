import { Card, CardContent, CardHeader, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IPlace } from "../../AppModelTypes";
import {
  isNullOrUndefined,
  numberToMoney,
  stripProviderName,
} from "../../Utils";
import usePlaceActions from "../../actions/place/PlaceActions";
import { useGetScrapeLinksQuery } from "../../api/PlacesApiSlice";
import { TypographyShortened } from "../TypographyShortened";
import SmartActionBar from "../buttons/SmartActionBar";
import LeafletImage from "../maps/LeafletImage";
import {
  fromKmNumberToDistanceText,
  gpsCoordsToDistanceInKm,
} from "./../../Utils";
import SmartCardActions from "./../others/SmartCardActions";

type IProps = {
  placeObject: IPlace;
  enableFlyTo?: boolean;
  fromLatiude?: number;
  fromLongitude?: number;
};

function PlaceSmall({
  placeObject,
  enableFlyTo,
  fromLatiude,
  fromLongitude,
}: IProps) {
  const placeId = placeObject.Id;
  const { onePlaceActions } = usePlaceActions(placeId);
  const [placePrice, setPlacePrice] = useState();
  const [originalPlacePrice, setOriginalPlacePrice] = useState<
    string | undefined
  >();
  const [placeCountry] = useState<string>(
    placeObject && placeObject.Land ? placeObject.Land : ""
  );
  const { data: scrapeLinksData } = useGetScrapeLinksQuery(placeId);
  const [distanceFrom, setDistanceFrom] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    if (placeObject && fromLatiude != null && fromLongitude != null) {
      const lat = placeObject.Latitude;
      const lon = placeObject.Longitude;
      if (lat != null && lon != null) {
        const distanceInKm = gpsCoordsToDistanceInKm(
          lon,
          lat,
          fromLongitude,
          fromLatiude
        );
        setDistanceFrom(distanceInKm);
      }
    } else {
      setDistanceFrom(undefined);
    }
  }, [fromLatiude, fromLongitude, placeObject]);

  useEffect(() => {
    if (scrapeLinksData && scrapeLinksData.records) {
      const firstRecord = scrapeLinksData.records[0];
      if (firstRecord) {
        const dataString = firstRecord.Data;
        if (dataString) {
          const data = JSON.parse(dataString);
          if (!isNullOrUndefined(data.preis)) {
            setPlacePrice(data.preis);
          }
          if (!isNullOrUndefined(data.preis_original)) {
            setOriginalPlacePrice(data.preis_original);
          }
        }
      }
    }
  }, [scrapeLinksData]);

  function _pimpTooltipWithOriginalPriceInfo(text: string) {
    let ret: string | any = text;
    if (
      originalPlacePrice !== null &&
      originalPlacePrice !== undefined &&
      originalPlacePrice.length > 0
    ) {
      const secondLine = "Preis laut Anbieter: " + originalPlacePrice;
      const allText = text + "\n" + secondLine;
      ret = <div style={{ whiteSpace: "pre-line" }}>{allText}</div>;
    }
    return ret;
  }
  const priceComponent =
    placePrice === null || placePrice === undefined || isNaN(placePrice) ? (
      <TypographyShortened
        key="price1"
        text="Preis unbekannt"
        cutLength={20}
        modifyTooltip={(t) => {
          return _pimpTooltipWithOriginalPriceInfo("Preis unbekannt");
        }}
      />
    ) : (
      <TypographyShortened
        key="price2"
        text={numberToMoney(placePrice)}
        cutLength={20}
        modifyTooltip={(t) => {
          return _pimpTooltipWithOriginalPriceInfo("Preis: " + t);
        }}
      />
    );
  const countryComponent = (
    <TypographyShortened
      key="country"
      text={placeCountry}
      cutLength={20}
      modifyTooltip={(t) => {
        return "Land: " + t;
      }}
    />
  );
  const providerUrl =
    scrapeLinksData &&
    scrapeLinksData.records &&
    scrapeLinksData.records.length > 0 &&
    scrapeLinksData.records[0].Url
      ? scrapeLinksData.records[0].Url
      : null;
  const providerComponent = providerUrl ? (
    <TypographyShortened
      key="provider"
      text={stripProviderName(providerUrl)}
      cutLength={20}
      modifyTooltip={(t) => {
        return "Anbieter: " + t;
      }}
    />
  ) : null;

  const lon = placeObject.Longitude;
  const lat = placeObject.Latitude;
  let mapImageComponent = null;
  if (lon && lat) {
    mapImageComponent = (
      <LeafletImage
        longitude={lon}
        latitude={lat}
        zoom={15}
        width="100px"
        height="100px"
        // showDebug={true}
      />
    );
  }

  let subheaderText = null;
  if (distanceFrom != null) {
    subheaderText = "Entfernung: " + fromKmNumberToDistanceText(distanceFrom);
  }

  const actionBar = (
    <SmartActionBar actions={onePlaceActions} showLabels={false} />
  );

  let ret = null;
  if (placeObject) {
    ret = (
      <Card
        raised={true}
        elevation={5}
        id={placeId}
        sx={{ m: 1.5, borderRadius: 2 }}
      >
        <CardHeader
          title={
            placeObject.Name != null ? placeObject.Name : "<" + placeId + ">"
          }
          sx={{ pb: 0 }}
          subheader={subheaderText}
        ></CardHeader>
        <CardContent sx={{ pb: 0 }}>
          <Stack
            key="outer_stack"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Stack
              key="inner_stack_left"
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              {providerComponent}
              {priceComponent}
              {countryComponent}
            </Stack>
            <Stack
              key="inner_stack_right"
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              {mapImageComponent}
            </Stack>
          </Stack>
        </CardContent>
        <SmartCardActions>{actionBar}</SmartCardActions>
      </Card>
    );
  }
  return ret;
}

export default PlaceSmall;
