import { ZoomInMapTwoTone } from "@mui/icons-material";
import React from "react";
import { IPlace } from "../../AppModelTypes";
import ISmartAction from "../../components/buttons/ISmartAction";
import { isJSONString } from "./../../Utils";

function createFlyToPlaceInMapAction(
  place: IPlace,
  navigate: (arg0: string) => void
): ISmartAction {
  const placeData = isJSONString(place.Data) ? JSON.parse(place.Data) : null;
  const locationIsInaccurate =
    placeData && placeData.gps_location === "inaccurate";
  const locationAccuracyPostText = locationIsInaccurate ? " (ungenau)" : "";
  const text = "In der Karte anzeigen" + locationAccuracyPostText;

  function handleFly() {
    if (place?.Id != null) {
      // sessionStorage.setItem("flyToPlaceId", JSON.stringify(place.Id));
      const url = "/map/" + place.Id;
      navigate(url);
    }
  }
  return {
    text,
    onMouse: handleFly,
    children: <ZoomInMapTwoTone />,
  };
}

export default createFlyToPlaceInMapAction;
