import { Stack } from "@mui/material";
import React from "react";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { IForm } from "./IForm";

// const ROLES = ["viewer", "editor"] as const;
// const MIN_CHARACTERS = 6;
// const FormModelSchema = z.object({
//   username: z.string().min(MIN_CHARACTERS, {
//     message: "Muss mindestens " + MIN_CHARACTERS + " Zeichen lang sein",
//   }),
//   role: z.enum(ROLES, {
//     errorMap: (issue, ctx) => ({
//       message: "Muss in den Optionen " + arrayToText(ROLES) + " enthalten sein",
//     }),
//   }),
// });
// type IFormModel = z.infer<typeof FormModelSchema>;
// const defaultValues: IFormModel = {
//   username: "Peter",
//   role: "viewer",
// };

function AddUserForm(props: IForm) {
  const { onError, onDataChange, form } = props;
  if (form == null) {
    return null;
  }
  // const formReturn = useForm<IFormModel>({
  //   defaultValues: defaultValues,
  //   resolver: zodResolver(FormModelSchema),
  //   mode: "all",
  // });
  const {
    control,
    watch,
    formState: { errors },
  } = form;

  watch((data: any) => {
    onDataChange(data);
    _handleErrors();
  });

  // useEffect(() => {
  //   // console.log("AddUserForm.useEffect() [errors] : errors: ", errors);
  //   // console.dir(errors);
  //   _handleErrors();
  // }, [_handleErrors, errors]);

  const ret = (
    <>
      <FormContainer
      // onError={handleError}
      >
        <Stack direction="column">
          <TextFieldElement
            name="username"
            control={control}
            label="Benutzer"
            required
          ></TextFieldElement>
          <TextFieldElement
            name="role"
            control={control}
            label="Rolle"
            required
          ></TextFieldElement>
        </Stack>
      </FormContainer>
      {/* <DevTool placement="top-right" control={control} /> */}
    </>
  );
  return ret;

  //**************************
  //     Helpers
  function _errorToText(error: any): string {
    let ret = error[0];
    if (error[1] && error[1].message) {
      ret = ret + ": " + error[1].message;
    }
    return ret;
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function _handleErrors() {
    let errorText = null;
    if (errors != null) {
      const errorEntries = Object.entries(errors);
      if (errorEntries.length > 0) {
        const firstError = errorEntries[0];
        errorText = _errorToText(firstError);
      }
    }
    onError(errorText);
  }
}

export default AddUserForm;
