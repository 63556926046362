import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { TitleContext } from "../TitleContext";

interface IProps {
  pageTitle?: string;
  page: JSX.Element;
}

function PageWrapper({ pageTitle, page }: IProps): JSX.Element | null {
  const titleContext = useContext(TitleContext);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pageTitle) {
      titleContext?.setTitle(pageTitle);
    }
  }, [pageTitle, titleContext]);

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return page;
}

export default React.memo(PageWrapper);